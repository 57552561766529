import React, { useContext } from 'react' 
import { Button, Container } from '@material-ui/core'
import {navigate} from 'gatsby'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { DataContext } from '../../../context/DataContext';
import { getDateDetailFromDB, getTimeDetailFromDB } from '../../../utils/datetime';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';

export default function PaymentNavbar() {
    const { t } = useTranslation();
    const {booking} = useContext(DataContext);
    const { language } = useContext(I18nextContext);

    return (
        <div 
        data-sal="slide-right"
        data-sal-duration="2000"
        className='payment-navbar'>
            <Container> 
                <p>{getDateDetailFromDB(booking?.book_init_datetime,language)} - {getTimeDetailFromDB(booking?.book_init_datetime)} {t('To')} {getDateDetailFromDB(booking?.book_end_datetime,language)} - {getTimeDetailFromDB(booking?.book_end_datetime)}   |   {`$${booking?.book_total}`}</p>
                <Button
                    color='primary'
                    startIcon={<ChevronLeftIcon />}
                    onClick={()=>navigate(-1)}
                >
                    {t('Back')}
                </Button>
            </Container>
        </div>
    )
}
