import React from 'react' 
import { Grid, Button } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'; 
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function WrongPaymentMessage({ setShowErrorMessage,error={}}) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} className='wrong-payment'>
            <Grid container item xs={12} justify={'center'}>
                <h1><ErrorOutlineIcon /></h1>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <h2>{error?.title}</h2>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <p>{error?.message}</p>
            </Grid>
            <Grid item xs={12}>
                <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    onClick={()=>setShowErrorMessage(false)}
                >
                   {t('Go Back')}
                </Button>
            </Grid>
        </Grid>
    )
}
