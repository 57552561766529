import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import DialogLayout from '../../../layouts/DialogLayout'
import './TermAndConditionModal.scss'

export default function TermAndConditionModal({ open, handleClose }) {
    const { t } = useTranslation();
    return (
        <DialogLayout
            title={t('Term and Conditions')}
            open={open}
            handleClose={handleClose}
            maxWidth='lg'
        >
            <div className='content-term-and-conditions'>
            <h3>1. IDENTIFICACIÓN DE LAS PARTES</h3>
            <p>Las presentes Cláusulas Generales de Contratación son establecidas, de una parte, por TIVAT COLOMBIA S.A.S, representada por Duilio Patricio Sanguineti identificado con PASAPORTE de EEUU No.642480171 (en adelante, “Wait N Rest”). Y, de otra parte, las presentes Cláusulas Generales de Contratación son aceptadas por el usuario, cliente, (en adelante, el “Cliente”).</p>
            <p>Los presentes Términos y Condiciones de servicio regulan la relación contractual entre los usuarios (en adelante, “Usuarios”), con TIVAT COLOMBIA SAS (NIT: 9015044725), en Adelante “Wait N Rest”. Los Usuarios se encontrarán sujetos a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas y principios que rigen Wait N Rest y que son incorporados al presente por referencia.</p>
            <p>Wait N’ Rest es un servicio que permite a los clientes utilizar una habitación para descansar durante un tiempo determinado por el Cliente, el cual no excederá de ocho (8) horas continuas registro presencial y hasta 10 horas por registro web.</p>
            <p>Las partes entienden y aceptan que existen autoridades de control y vigilancia que tienen la facultad de inspeccionar la habitación, en cualquier momento y cualquiera que sea el motivo, sin necesidad de dar un previo aviso y sin requerir la autorización del Cliente.</p>
            <p>Wait N’ Rest se reserva el derecho de modificar este reglamento sin necesidad de dar un previo aviso al Cliente.</p>
            <br></br>

            <h3>2. REPRODUCCIÓN DE CONTENIDOS</h3>
            <p>Salvo indicación en contrario, se prohíbe expresamente la reproducción, comunicación, modificación o distribución total o parcial, por cualquier medio de difusión público o privado, de los contenidos de la Web y aplicativo de Wait N Rest, sin la autorización expresa del titular de los derechos de propiedad intelectual, Wait N Rest.</p>
            <p>Tales actos sobre los contenidos o elementos que integren la Web y aplicativo sólo serán posibles mediante contrato suscrito entre el interesado y Wait N Rest y bajo los términos y condiciones que en el mismo se estipulen, reservándose Wait N Rest el ejercicio de cuantas acciones sean pertinentes legalmente en defensa de sus derechos de propiedad intelectual, los cuales reconoce, entiende y acepta que son de uso exclusivo de Wait N Rest.</p>
            <br></br>

            <h3>3. SERVICIOS</h3>
            <p>Wait N Rest ofrece un servicio de habitaciones de descanso de cobro horario ubicados dentro del Aeropuerto Internacional El Dorado Muelle internacional, destinado exclusivamente a descansar durante el tiempo que el cliente así lo requiera y que en cualquier caso no exceda de 8 horas (en adelante, el “Tiempo Máximo”) consecutivas, en habitaciones equipadas con aire acondicionado, además de, regular la iluminación, temperatura, adquirir productos en la tienda virtual de Wait N Rest y consulta de información de vuelos y acceso a wifi.</p>
            <p>Una vez contratado el servicio, bien sea a través de nuestro sitio web, o directamente en la sede del aeropuerto, el Cliente recibirá un correo de confirmación donde se indicará, ciudad, aeropuerto, terminal, fecha y hora del check-in y check-out, tiempo de estadía, código de entrada a la habitación, y el valor del servicio contratado. El servicio contratado por el Cliente es personal e intransferible y es válido exclusivamente para la habitación especificada en el Correo de Confirmación.</p>
            <p>El Servicio sujeto a disponibilidad limitada de Habitaciones presentes en el aeropuerto elegido por el Cliente antes de la compra. Con la realización de la reserva, el cliente manifiesta que ha leído y comprendido el reglamento de uso de las Habitaciones (el 'Reglamento') publicado en el Sitio y que en consecuencia acepta estas Condiciones Generales, y el Reglamento. Para acceder a la Habitación reservada e indicada en el Correo de Aceptación, el Cliente debe ingresar el código de reserva recibido por correo electrónico.</p>
            <p>En caso de que el Cliente contrate el servicio personalmente en la Sede, a través de las pantallas táctiles, el tiempo de estadía está sujeto a la disponibilidad de habitaciones al momento de contratación, por lo que el tiempo se liquidará cuando el Cliente realice el check-out.</p>
            <p>En el evento en que no hubiere disponibilidad de habitaciones por ocupación o reservas, al momento de contratar el servicio, el Cliente podrá registrarse dejando sus datos de contacto con el fin de que el sistema le notifique el momento en que haya disponibilidad de habitaciones. El cliente podrá aceptar o rechazar la notificación de disponibilidad que el sistema haga.</p>
            <br></br>

            <h3>4. Celebración del contrato</h3>
            <p>Las partes reconocen, entienden y aceptan que el servicio de hospedaje contratado, constituye un acuerdo de voluntades entre el Cliente y Wait N Rest, que se rige por los términos y condiciones de este escrito, además de observar lo establecido en la legislación colombiana particularmente Código Civil, Código de Comercio, Ley 1480 de 2011 referente al Estatuto del Consumidor y demás Normas concordantes. Se entiende que la información publicada en la pantalla de resumen son una Oferta de Wait N Rest que podrá ser aceptada o no por el Cliente y que se condiciona a los términos de este escrito.</p>
            <p>Al contratar el servicio, el Cliente reconoce haber leído y entendido los términos y condiciones de su contratación, al tiempo que acepta su contenido.</p>
            <p>Previo a la contratación del servicio, Wait N Rest revisará la disponibilidad de habitaciones y horarios deseados por el Cliente. Una vez Wait N Rest confirme la disponibilidad de la habitación reservada enviará un correo al cliente confirmando la reserva realizada. En este momento habrá quedado contratado el servicio, quedando el Cliente en la obligación de hacer el pago de la reserva confirmada, de manera inmediata, so pena de quedar cancelada la reserva realizada por el Cliente, y confirmada por Wait N Rest.</p>
            <br></br>

            <h3>5. Proceso de reserva</h3>
            <p>El cliente podrá reservar el uso de una habitación Wait N’ Rest a través del registro en las pantallas táctiles ubicadas al exterior de la sede o haciendo uso del portal web de reservas www.waitnrest.com. El proceso de registro requiere al cliente colocar una contraseña para su habitación y recordar el número de habitación asignado por el sistema de Wait N Rest para poder ingresar a la sede y posteriormente acceder a la habitación reservada. El Cliente tiene derecho a reservar las habitaciones de acuerdo con lo dispuesto en los presentes Términos y Condiciones. El uso de las habitaciones es individual y con observancia del Reglamento y de los Términos y condiciones acá descritos. La aplicación de sanciones de conformidad con el art. 8 de estas Condiciones Generales, en caso de incumplimiento o cumplimiento irregular por parte del Cliente de las obligaciones referidas en estas Condiciones Generales y el Reglamento, y en particular en caso de negligencia, culpa, negligencia e inexperiencia del Cliente, este último responderá a Wait N Rest por los daños y perjuicios que sufra atribuibles y deberá pagar la compensación correspondiente.</p>
            <br></br>

            <h3>6. Contraprestación</h3>
            <p>La contraprestación debida por el Cliente por el Servicio adquirido se indicará en dólares en la Pantalla de resumen e incluirá todos los impuestos o aranceles aplicables. El cliente decidirá qué tarifa escoge en el momento de hacer la reserva. La selección del Servicio y tarifa elegida por el Cliente.</p>
            <br></br>

            <h3>7. Pago del importe y facturación</h3>
            <p>El Cliente realizará el pago de la tarifa correspondiente a través de STRIPE. Wait N Rest no podrá en ningún paso del proceso de compra conocer la información relativa a la tarjeta de crédito y / o débito del Cliente. En ningún caso, Wait N Rest se hace responsable de cualquier uso fraudulento e indebido de tarjetas de crédito o débito por parte de terceros, al momento del pago del servicio adquirido en la web o pantallas táctiles ubicadas en la sede de Wait N Rest.</p>
            <br></br>

            <h3>8. Uso de la habitación WAIT N’ REST</h3>
            <p>El Cliente entiende y acepta que deberá cumplir con las disposiciones de uso descritas en las condiciones generales de uso del presente documento y también el reglamento de uso del aeropuerto donde la habitación Wait N’ Rest esté ubicada, sin excepción. El cliente acepta que la reserva es personal e intransferible y que el uso de la habitación es individual y el tiempo máximo de estadía es de ocho (8) horas consecutivas. Está prohibido retirar equipos de la habitación. En el evento en que el Cliente incumpla el reglamento, Wait N Rest podrá dar aviso a las autoridades pertinentes. El cliente que utilizó la habitación por última vez será quien tome responsabilidad por los daños y perjuicios reportados por el personal de limpieza de Wait N’ Rest. El monto correspondiente por daños de muebles y equipos reportados dependerá y será debitado del medio de pago registrado del cliente responsable sin perjuicio del derecho del cliente de demostrar que el daño reportado dependía de causas que no pueden ser atribuibles a él.</p>
            <br></br>

            <h3>9. Resolución</h3>
            <p>En los casos que el Cliente: a) Sea menor de 18 años; b) Haga uso de credenciales y/o documentos falsos, al reservar la habitación; c) Incumpla una de las obligaciones relativas a las condiciones de uso de las habitaciones según lo establecido en el Reglamento; constituyen casos de incumplimiento grave, sobre cuya ocurrencia Tivat Colombia S.A.S. tiene derecho a rescindir del contrato de conformidad con el ordenamiento jurídico colombiano por el hecho y culpa del Cliente y sin perjuicio de indemnización por daños adicionales, mediante notificación simple por escrito.</p>
            <br></br>

            <h3>10. Uso de habitaciones para personas con movilidad reducida</h3>
            <p>Por disposiciones legales Tivat Colombia S.A.S. tiene una habitación habilitada para personas con movilidad reducida la cual, en caso de no estar reservada para una persona con tal condición, podrá ser reservada por un Cliente sin dicha condición. Tivat Colombia S.A.S., advierte que en el evento en que no haya disponibilidad de habitaciones por ocupación, y se generen reservas de espera en la habitación para personal con movilidad reducida, dicha persona tendrá prioridad sobre los demás que hubieren reservado.</p>
            <br></br>

            <h3>11. Ingreso a la habitación</h3>
            <p>El Cliente deberá usar la contraseña colocada en el proceso de reserva para ingresar la habitación Wait N’ Rest. Es responsabilidad del cliente recordar la contraseña de su habitación y el número de la habitación que reservó. En caso de olvidar dicho número el Cliente deberá comunicarse con la central de ayuda Wait N’ Rest a través del correo electrónico waitnrestcontact@gmail.com o solicitar asistencia a un empleado de Wait N’ Rest en el lugar utilizando el botón de ayuda en las pantallas táctiles del exterior de la sede. Bajo ninguna circunstancia Wait N’ Rest dará un reembolso por el tiempo del servicio consumido. El cliente podrá salir y entrar a la habitación y la sede las veces que desee utilizando su clave de acceso. El tiempo de estadía en la habitación Wait N’ Rest iniciará desde la culminación del proceso de registro (al finalizar la colocación de la información de cobro), y finalizará con la culminación del proceso de check out realizado en la pantalla táctil ubicada al exterior de la habitación utilizada. La habitación Wait N’ Rest se mantendrá con seguro durante todo el tiempo de estadía del cliente en la misma. Solo se abrirá con su clave única y la llave de acceso del personal de limpieza.</p>
            <br></br>

            <h3>12. Proceso de salida de la habitación </h3>
            <p>Para iniciar el proceso de salida de la habitación Wait N’ Rest, el Cliente deberá oprimir el botón de checkout ubicado en la pantalla táctil externa de la habitación. Posteriormente deberá colocar la clave de acceso única que utilizó durante su estadía y apretar el botón de “siguiente” hasta visualizar la pantalla que dice “Checkout completado”. Una vez culminado el proceso de Checkout, el tiempo de servicio finalizará y se cobrará el monto adeudado a la tarjeta de crédito o débito registrada por el Cliente. La habitación Wait N’ Rest no permitirá que se culmine con el proceso de checkout si la puerta de la habitación se encuentra abierta. Una vez culminado el proceso de salida, la clave de acceso del Cliente no funcionará más para dicha habitación, razón por la cual en caso querer volver a ingresar deberá hacer un nuevo registro. Es responsabilidad del Cliente realizar el proceso de checkout al finalizar el uso de la Habitación, de lo contrario, se cargará a la tarjeta registrada del cliente el monto máximo de estadía en la habitación. El tiempo máximo de salida para realizar check out de la habitación es de cinco (5) minutos, pasado ese tiempo se realizara el cobro de la siguiente hora o fracción.</p>
            <br></br>

            <h3>13.	Devoluciones</h3>
            <p>Sólo tendrán derecho a devoluciones de dinero los clientes que, pese a haber reservado la habitación vía web no pudieron acceder a ella a la hora de inicio de la reserva debido a que la habitación no estaba lista para su uso por estar en proceso de limpieza o  quienes siguiendo las instrucciones dispuestas en nuestros términos y condiciones y reglas de uso, soliciten la cancelación de su reserva web considerando el tiempo mínimo establecido de 48 horas antes del inicio de dicha reserva. El cliente acepta que el proceso de devolución del dinero estará a cargo de la plataforma procesadora de pagos STRIPE cuyas políticas pueden consultarse en https://stripe.com/docs/refunds. El cliente acepta y entiende que TIVAT COLOMBIA SAS no tiene ninguna injerencia en la rapidez de la devolución de los fondos, y que el único documento que puede entregar al Cliente como evidencia de la solicitud de reembolso es la copia de la solicitud de reembolso emitida desde la plataforma STRIPE. </p>
            <br></br>

            <h3>14.	Alertas y comunicaciones</h3>
            <p>Wait N Rest se reserva el derecho de cambiar el número de la habitación reservada por el Cliente vía web si así lo considera pertinente. Dicho cambio será comunicado por Wait N Rest al Cliente con el envió de un correo electrónico a la dirección consignada en el proceso de reserva o por el personal en sede, Es responsabilidad del cliente verificar su correo antes de la fecha de su reserva para estar al tanto de cualquier modificación. Wait N Rest enviará alertas al correo electrónico consignado del Cliente para: avisar sobre la culminación del servicio adquirido en caso se trate de una reserva realizada via web, para avisar sobre la cercanía al tiempo máximo permitido por estadía el cual es de ocho (8) horas, y para enviar la información del checkout/ checkin o penalidades cargadas al Cliente ante el incumplimiento del reglamento de uso de las habitaciones Wait N Rest.</p>
            <br></br>

            <h3>15. Daños</h3>
            <p>Es responsabilidad del cliente notificar al servicio al cliente de Wait N’ Rest a través del correo electrónico a waitnrestcontact@gmail.com o dando aviso a un miembro del personal de limpieza de Wait N’ Rest sobre cualquier daño a los muebles o equipos de la habitación al momento de ingresar. El Cliente podrá optar por el reembolso del dinero o el cambio de habitación sujeto a disponibilidad. En caso de pérdida de conexión generalizada del internet en el aeropuerto o falla del sistema operativo de Wait N’ Rest el cliente deberá notificar a un miembro del personal de Wait N’ Rest sobre la situación presentada para poder solicitar realizar un check-out manual</p>
            <br></br>

            <h3>16. Objetos extraviados</h3>
            <p>Wait N’ Rest no se responsabiliza en ningún caso por: la pérdida o daño de objetos y pertenencias personales del cliente abandonadas en el interior de la habitación ni por las consecuencias que pueda implicar al Cliente el tiempo que se tome el personal de limpieza de Wait N’ Rest para recuperar pertenencias del cliente dejadas en el interior de la habitación. Wait N’ Rest hará el mejor esfuerzo posible por entregarle al Cliente sus pertenencias personales siempre y cuando su pérdida se notifique en los primeros 10 minutos posteriores a la realización del checkout. Wait N Rest conservará los objetos extraviados y encontrados por el personal por un periodo máximo de 30 días calendario. En caso de que no sean reclamados, dichos objetos serán donados a la caridad o eliminados.</p>
            <br></br>

            <h3>17. Exención de responsabilidad</h3>
            <p>Excepto los casos de dolo o negligencia grave de Wait N Rest, este último no es responsable de los daños directos o indirectos de cualquier naturaleza que el Cliente o terceros puedan sufrir.</p>
            <p>Wait N Rest no se hace responsable por pérdidas causadas por robo y / o pérdida de artículos dentro o en los alrededores de la habitación u objetos dejados por el Cliente dentro de la habitación o sus alrededores. El Gerente se encargará de dichos objetos dejados y que hayan sido encontrados por el personal, por un período de 30 días calendario. De no reclamarse el objeto extraviado dentro del plazo señalado, el objeto será donado a la caridad o eliminado.</p>
            <br></br>

            <h3>18. Tarifas</h3>
            <p>El cliente declara conocer las tarifas del servicio antes de registrarse para el uso de la habitación Wait n’ Rest. Estas serán mostradas durante el proceso de registro y reserva de habitación en la pantalla táctil ubicada al exterior de la sede Wait N’ Rest y en el portal web de reservas. Además, el cliente declara haber visitado www.waitnrest.com/bogota antes de acceder a nuestro servicio.</p>
            <br></br>

            <h3>19. Pagos</h3>
            <p>El cliente declara conocer el método de pago del servicio Wait N’ Rest antes de reservar y registrarse para el uso de la habitación Wait n’ Rest. Este método consiste en un cobro del uso de la habitación que se carga por hora de consumo. La tarifa horaria aplica de igual forma para la hora o fracción en minutos del uso de la habitación. Este método solo aplica para las reservas realizadas por el Cliente en las pantallas táctiles ubicadas al exterior de la sede Wait N Rest. Durante el proceso de reserva, el cliente acepta que la pasarela de pago STRIPE realizará una pre autorización a la tarjeta consignada del Cliente de 83.92 dólares. Con la realización del proceso de check-out de la habitación, nuestro sistema captura únicamente el monto correspondiente al tiempo de estadía del Cliente. La liberación del saldo restante 'congelado' dependerá de las políticas de retención de autorización del banco emisor de la tarjeta del Cliente. El Cliente declara aceptar y conocer que TIVAT COLOMBIA SAS no tiene ninguna responsabilidad bajo ninguna circunstancia sobre el tiempo en el que los fondos congelados se reflejarán en el medio de pago consignado por el Cliente y que dicho procedimiento dependerá única y exclusivamente del banco emisor de la tarjeta del Cliente. El cliente acepta y entiende que la pre autorización de 83.92 dólares no es equivalente a un cargo, y por tanto; no es dinero que TIVAT COLOMBIA SAS recibe en dicho momento; por el contrario, es dinero “congelado” por la pasarela de pago STRIPE para que posterior al check-out, la pasarela de pago pueda capturar el monto correspondiente y enviarlo a TIVAT COLOMBIA SAS.</p>
            <br></br>

            <h3>20. Aplicación</h3>
            <p>Wait N’ Rest adopta las medidas de seguridad necesarias y razonables para el funcionamiento de la Aplicación utilizada por las pantallas táctiles ubicadas al exterior de la habitación Wait N’ Rest. Sin embargo, estas medidas no necesariamente aseguran la ausencia de virus ni de otros elementos en la Aplicación, introducidos por terceros, ajenos al control de Wait N’ Rest, que puedan producir alteraciones en los sistemas físicos o lógicos del Usuario Registrado o en los documentos electrónicos y ficheros almacenados en sus sistemas. En consecuencia, Wait N’ Rest no será responsable de los daños y perjuicios que pudieran derivarse de la presencia de virus u otros elementos, ajenos al control de Wait N’ Rest, que puedan producir alteraciones en los sistemas físicos o lógicos, documentos electrónicos o ficheros del Usuario Registrado.</p>
            <br></br>

            <h3>21. Protección</h3>
            <p>Wait N’ Rest adopta diversas medidas para proteger la Aplicación y los contenidos contra ataques informáticos de terceros. No obstante, Wait N’ Rest no garantiza que terceros no autorizados no accedan a las condiciones, características y circunstancias en las cuales el Cliente accede a la Aplicación. En consecuencia, Wait N’ Rest no será en ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no autorizado.</p>
            <br></br>

            <h3>22. Procesamiento de pagos</h3>
            <p>Los pagos realizados por el Cliente se harán a través de la plataforma de pagos STRIPE. El cliente declara haber leído las políticas de privacidad y tratamiento de datos de STRIPE que se puede consultar en https://stripe.com/privacy</p>
            <p>En caso de reversiones o contra cargos, el Cliente declara conocer que Wait N’ Rest no tiene ninguna responsabilidad sobre la rapidez de la devolución del dinero y que se rige por la política de contra cargos y reversiones de STRIPE que se puede consultar en https://stripe.com/docs/refunds</p>
            <br></br>

            <h3>23. Penalidades</h3>
            <p>Wait N Rest se reserva el derecho de aplicar al Cliente las sanciones indicadas en este artículo cuando se produzcan los supuestos previstos y regulados por las Condiciones Generales y por Regulación, sin perjuicio, en ningún caso, de la indemnización por daños mayores. En particular Wait N Rest le comunica al Cliente a través de una partida de débito adicional en el recibo el monto de la multa, que se cargará simultáneamente a la tarjeta de crédito o débito utilizada por el Cliente para pagar el Servicio. Tipo de sanciones aplicables. En cualquier caso, el Cliente entiende que se considerará como un uso indebido de la habitación, lo que contravenga lo contenido en este escrito de Términos y Condiciones, además de lo contenido en el Reglamento de Uso, el cual hace parte integral de este documento.</p>
            <br></br>

            <h3>24 - Disposiciones generales</h3>
            <p>La aceptación de estas Condiciones Generales es necesaria para realizar la reserva. Las Condiciones Generales pueden estar sujetas a cambios y la versión más actualizada siempre estará disponible en el Sitio. Wait N Rest se reserva el derecho de modificar estas Condiciones Generales en cualquier momento, sin previo aviso, entendiéndose que la compra por parte del Cliente, validado por Wait N Rest, de acuerdo con las disposiciones aquí definidas Condiciones Generales, se regirán por las Condiciones Generales contenidas en el correo electrónico de Aceptación. Al adquirir el Servicio, el Cliente declara ser mayor de edad. Estas disposiciones generales se rigen por el Estatuto del Consumidor contenido en la Ley 1480 de 2011 y por las demás pertinentes ofrecidas por el ordenamiento jurídico colombiano.</p>
            <p>Para cualquier necesidad o queja conectado a la compra de los Servicios en el Sitio, el Cliente puede comunicarse con personal en sede o a través del correo waitnrestcontact@gmail.com</p>
            <br></br>

            <h3>25 - Membresias y descuentos</h3>
            <p>Los Clientes que cuenten con membresías o descuentos aplicables para el servicio de Wait N Rest, serán los únicos responsables de indicar dicha condición especial al personal de la sede de Wait N´Rest antes de la reserva y pago de una habitación. Bajo ninguna circunstancia, TIVAT COLOMBIA SAS, realizará reembolsos, devoluciones o cancelaciones de visitas de membresías o descuentos después de culminado el proceso de reserva de una habitación. El Cliente acepta y entiende que el beneficio de la membresía de Priority Pass y/o Lounge Key será válido únicamente para la franja horaria desde las 8:00 AM hasta la 21:00 PM, sin importar lo que figure en cualquier medio de comunicación que el Cliente tenga con Priority Pass y/o Lounge Key.</p>
            <p>Los Clientes pertenecientes al programa de beneficios de Priority Pass y/o Lounge Key podrán ingresar a la sede Wait N´Rest con un máximo de (1) acompañante o invitado por registro. Es responsabilidad del Cliente titular de la membresia asegurarse que su programa de beneficios le incluye la invitación gratuita de acompañantes a los establecimientos con convenio con Priority Pass y Lounge Key.</p>
            <p>Bajo ninguna circunstancia TIVAT COLOMBIA SAS se responsabiliza por cobros posteriores que realicen entidades bancarias al titular de la membresia Priority Pass y/o Lounge Key.</p>
            <p>El cliente acepta que podrá aplicar un solo descuento por registro o reserva de habitación.</p>
            <br></br>

            <h3>26. Ley aplicable y controversias</h3>
            <p>Los términos y condiciones consignados en este escrito se rigen por la ley colombiana.</p>
            <p>Toda controversia o diferencia relativa a este escrito, así como a su ejecución que no sea arreglada directamente entre las partes, se resolverá por las salas de conciliación del Centro de Arbitraje y Conciliación Mercantiles de la Cámara de Comercio de Bogotá. El conciliador se sujetará a lo dispuesto en los Códigos de Procedimiento civil y de Comercio de acuerdo con las siguientes reglas:</p>
            <p>a. La sala de conciliación estará integrada por un conciliador, designado por la Cámara de Comercio de Bogotá.</p>
            <p>b. El Conciliador se sujetará a las Reglas previstas para el efecto por el Centro de Arbitraje y Conciliación Mercantiles de la Cámara de Comercio de Bogotá.</p>
            <br></br>

            <h3>27. Declaración Final</h3>
            <p>EL USUARIO DECLARA HABER LEÍDO Y ENTENDIDO TODAS LAS CONDICIONES ESTABLECIDAS EN LAS POLÍTICAS DE PRIVACIDAD Y LOS PRESENTES TÉRMINOS Y CONDICIONES GENERALES, Y MANIFIESTA SU CONFORMIDAD Y ACEPTACIÓN AL MOMENTO DE RESERVAR UNA HABITACIÓN. CUALQUIER PERSONA QUE NO ACEPTE O SE ENCUENTRE EN DESACUERDO CON ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SERVICIO.</p>
            <p>Wait and Rest es un servicio de estancias por horas, por lo cual y como lo establece la ley:</p>
            <p>¨Ley 300 de 1996 Artículo 2.2.4.4.12.4. Tarjeta de Registro Hotelero. establecimientos de alojamiento y hospedaje, excluidos los establecimientos que prestan servicio de alojamiento por horas¨ Por su parte, el artículo 12 de la Ley 1101 de 2006 modificado por el Decreto 229 de 2017, señala que los prestadores de servicios turísticos que se relacionan a continuación, deben inscribirse en el Registro Nacional de Turismo:¨Los hoteles, centros vacacionales, campamentos, viviendas turísticas y otros tipos de hospedaje no permanente, excluidos los establecimientos que prestan el servicio de alojamiento por horas¨</p>
            <br></br>

            <h3>Reglas De Uso</h3>
            <p>1) La habitación es sólo para uso individual. En caso de ingreso de dos personas o más durante tu estadía generará una penalización de 80 $ USD que se cargará a la tarjeta registrada en la reserva. Además, todos deberán retirarse de las instalaciones de Wait N’ Rest sin lugar a reclamo o reembolso.</p>
            <p>2) No está permitida ninguna modalidad de Fumar en el interior de nuestras instalaciones siendo un acto sancionable. La penalidad por incumplir con esta norma es de 100 USD y se cargará automáticamente a la tarjeta consignada en la reserva. (Tabaco, vapers, sustancias psicoactivas)</p>
            <p>3) Exceder el tiempo máximo de estadía en sede o el tiempo consignado de salida en la reserva web, se penalizará con una tarifa horaria de 17.2 USD por el tiempo excedido (hora o fracción). Se le otorgará al usuario 5 minutos de tolerancia para su salida y a partir de ahi se le cobrará la tarifa horaria descrita.</p>
            <p>4) Destruir o desactivar los detectores de humo resultará en una penalidad de 100 USD que se cargará a la tarjeta consignada en la reserva.</p>
            <p>5) Los objetos olvidados dentro de nuestras instalaciones serán guardados en las oficinas de Tivat Colombia SAS por un período de 30 días calendario. Luego de este tiempo, de no haber ningún reclamo por lo mismos, pasarán a ser donados a la caridad. La entrega de objetos extraviados se realizará únicamente al titular de la reserva en la que se extraviaron dichos objetos.</p>
            <p>6) Wait N rest en alianza con la Zona R del aeropuerto permitirá el ingreso de comida comprada en dichos establecimientos autorizados.</p>
            <p>7) Destruir o malograr la pantalla táctil ubicada al interior de la habitación resultará en una penalidad de 100 USD que se cargará a la tarjeta consignada en la reserva.</p>
            <p>8) No está permitido llevarse elementos del interior de la habitación. Incumplir con esta norma resultará en una penalidad equivalente al monto que TIVAT COLOMBIA SAS considere necesario para reponer el producto faltante considerando los daños que esta actitud pueda ocasionar al servicio brindado. El personal realizara un inventario previo a la entrega de la habitación y así tener en control el faltante o daño a las instalaciones.</p>
            <p>9) La edad mínima permitida para hacer uso de la habitación es de 18 años.</p>
            <p>10) Una vez que el huésped haya cancelado el importe por la tarifa de la habitación no se harán reembolsos bajo ningún concepto o circunstancias</p>
            <p>11) El alojamiento es intransferible no aceptándose otras personas en cambio sus titulares.</p>
            <p>12) Los huéspedes de acuerdo a las normas generales de convivencia y respeto, deberán tener especial cuidado con el volumen de aquellos aparatos electrónicos/eléctricos, que generen ruido o sonidos fuertes, velando no sobrepasar los decibeles permitidos por las capsulas.</p>
            <p>13) En caso de robos, hurtos, o daños intencionados serán denunciados inmediatamente con la policía local.</p>
            <p>14) El ingreso de animales de compañía - apoyo emocional y mascotas deberá reportarse con el personal de la sede, si se tiene algún daño en el mobiliario el costo de penalidad será de hasta 100 USD o debiendo abonar en su totalidad la reparación que TIVAT COLOMBIA SAS considere de los mismos.</p>
            <p>15) El daño a bienes muebles o al inmueble de propiedad de las habitaciones de descanso, será de exclusiva responsabilidad del huésped, debiendo abonar la reparación que TIVAT COLOMBIA SAS considere de los mismos</p>
            <p>16) Para quejas y/o sugerencias, diríjase al front desk y pregunte por el Jefe de Sede o supervisor de turno.</p>
            <p>17) Wait N Rest, declina toda responsabilidad por la pérdida de joyas, dinero, documentos u otros valores de cualquier clase conservados en las habitaciones, por existir una contraseña única de acceso a la habitación que el huésped asignara a su hora de ingreso.</p>
            <p>18) Todas las personas que se hospeden en las habitaciones de descanso de Wait N Rest, estarán sujetas a este reglamento, el que considera un contrato de adhesión que se debe cumplir, en caso contrario, Wait N Rest se reserva el derecho de admisión y permanencia.</p>
            <p>19) Los huéspedes que presenten una actitud agresiva, amenazante, en estado de embriaguez o que falten el respeto de cualquier forma, ya sea al personal u otros huéspedes deberán abandonar las habitaciones de descanso de inmediato y serán denunciados ante la autoridad competente.</p>
            <p>20) El tiempo mínimo para solicitar el reembolso de una reservación via web es de 48 hora antes del inicio de la reserva. Para que esta solicitud sea válida, el cliente deberá enviar un correo a waitnrestcontact@gmail.com solicitando dicho reembolso.</p>
            <p>21) Dejar basura, restos, ensuciar, mojar las superficies o colchón o cualquier otra actividad que implique una limpieza diferenciada de la habitación para poder habilitarla para un cliente posterior resultará en una multa de hasta 100 USD a la tarjeta consignada del cliente que se registró para el uso de dicha habitación. El gerente de sede podrá decidir si cobrar 100 dólares un monto menor dependiendo de la gravedad del estado en el que el cliente dejó la habitación.</p>
            <br></br>










            </div>
        </DialogLayout>
    )
}
