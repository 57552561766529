import React, {  useState } from 'react' 
import DialogLayout from './../../../layouts/DialogLayout'
import CardPaymentForm from './CardPaymentForm'
import WrongPaymentMessage from './WrongPaymentMessage' 
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_TOKEN } from '../../../contants/apis'
 
const stripePromise = loadStripe(STRIPE_TOKEN);

export default function CardPaymentModal({ open = false, handleClose = () => { } }) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [error, setError] = useState({});
  // const [token, setToken] = useState(null);
  const { t } = useTranslation();

  // useEffect(() => {
  //   getClientTokenBraintree()
  //     .then(value => {
  //       console.log(value);
  //       setToken(value.token);
  //       //renderPayment(value.token);
  //     })
  //     .catch(error => {
  //       console.log(error.message)
  //     });
  // }, [])

  return (
    <div className={'account-dialog'}>
      <DialogLayout
        open={open}
        handleClose={handleClose}
        title={t('Card Payment')}
      >
        {(!showErrorMessage) &&
          <Elements stripe={stripePromise}>
            <CardPaymentForm setShowErrorMessage={setShowErrorMessage} setError={setError} />
          </Elements>
        } 
        {showErrorMessage && <WrongPaymentMessage setShowErrorMessage={setShowErrorMessage} error={error} />}
      </DialogLayout>
    </div>
  )
}
