import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import DialogLayout from '../../../layouts/DialogLayout'
import './PrivacyPolicyModal.scss'

export default function PrivacyPolicyModal({ open, handleClose }) {
    const { t } = useTranslation();
    return (
        <DialogLayout
            title={t('Privacy policy')}
            open={open}
            handleClose={handleClose}
            maxWidth='lg'
        >
            <div className='content-term-and-conditions'>
            <h3>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</h3>
            <p>Conforme lo establecido en el artículo 15 de la Constitución Política, ley 1266 de 2008, ley  1581 de 2012l, decretos reglamentarios 1727 de 2009 y 2952 de 2010, decreto reglamentario parcial No 1377 de 2013, Sentencias de la Corte Constitucional C – 1011 de 2008, y C – 748 del 2011.</p>
            <p>Esta Política se aplicará a todos los Datos Personales que TIVAT  COLOMBIA S.A.S, (en adelante WNR) identificada con el NIT 901504472-5 recolecta, conserva, ordena, almacena, modifica, relaciona, usa, circula, evalúa, transfiere, transmite suprime, y, en general, procesa en el curso de las actividades (el “Tratamiento”) que realice TIVAT  COLOMBIA S.A.S en el desarrollo de sus actividades comerciales, en la relación con sus empleados, usuarios, proveedores y clientes tanto en Colombia como a nivel internacional, sus empleados y/o por aquellos terceros con los que WNR acuerde la realización de cualquier actividad relativa a, o relacionada con, el Tratamiento de Datos Personales, de los cuales WNR es responsable. Esta Política también será aplicable al Tratamiento que WNR realice a través de otros sitios web operados por WNR o sus afiliadas.</p>
            <p>Además, esta Política se aplicará a los terceros con quienes WNR eventualmente suscriba contratos de transmisión de Datos Personales, con el fin de que tales terceras personas conozcan las obligaciones que les aplicarán cuando traten Datos Personales por cuenta de WNR.</p>
            <br></br>

            <h3>PRINCIPIOS GENERALES Y POSTULADOS</h3>
            <p>WNR, garantiza la protección de derechos como el Habeas Data, la privacidad, la intimidad, el buen nombre, la imagen y la autonomía, con tal propósito todas las actuaciones se regirán por principios de buena fe, legalidad, autodeterminación informática, libertad y transparencia. </p>
            <p>Las personas que en ejercicio de cualquier actividad, incluyendo comerciales y laborales, sean éstas permanentes u ocasionales, pueda suministrar cualquier tipo de información o dato personal a WNR y en la cual ésta actúe como encargada del tratamiento o responsable del tratamiento, podrá conocerla, actualizarla y rectificarla.</p>
            <br></br>

            <h3>DEFINICIONES</h3>
            <p>De conformidad con la legislación vigente sobre la materia, se establecen las siguientes definiciones, las cuales serán aplicadas e implementadas acogiendo los criterios de interpretación que garanticen una aplicación sistemática e integral, y en consonancia con los avances tecnológicos, la neutralidad tecnológica; y los demás principios y postulados que rigen los derechos fundamentales que circundan, orbitan y rodean el derecho de habeas data y protección de datos personales.</p>
            <p>Autorización: Consentimiento previo, expreso e informado del titular para llevar a cabo el Tratamiento de datos personales.</p>
            <p>Base de datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.</p>
            <p>Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</p>
            <p>Dato público: Es el dato calificado como tal según los mandatos de la ley o de la Constitución Política y todos aquellos que no sean semiprivados o privados, de conformidad con la presente ley. Son públicos, entre otros, los datos contenidos en documentos públicos, sentencias judiciales debidamente ejecutoriadas que no estén sometidos a reserva y los relativos al estado civil de las personas.</p>
            <p>Dato privado: Es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular.</p>
            <p>Dato semiprivado: Es semiprivado el dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto sector o grupo de personas o a la sociedad en general, como el dato financiero y crediticio de actividad comercial o de servicios.</p>
            <p>Encargado del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del responsable del tratamiento. </p>
            <p>Responsable del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos. </p>
            <p>Titular: Persona natural cuyos datos personales sean objeto de tratamiento. </p>
            <p>Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión. </p>
            <p>Transferencia: Tiene lugar cuando el Responsable y/o Encargado del Tratamiento de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.</p>
            <p>Transmisión: Tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia, cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del Responsable.</p>
            <br></br>

            <h3>PRINCIPIOS ESPECÍFICOS</h3>
            <p>WNR aplicará principios específicos que se establecen a continuación, los cuales constituyen las reglas a seguir en la recolección, manejo, uso, tratamiento, almacenamiento e intercambio, de datos personales: </p>
            <p>Principio de legalidad: en el uso, captura, recolección y tratamiento de datos personales, se dará aplicación a las disposiciones vigentes y aplicables que rigen el tratamiento de datos personales y demás derechos fundamentales conexos. </p>
            <p>Principio de libertad: el uso, captura, recolección y tratamiento de datos personales sólo puede llevarse a cabo con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal, estatutario, o judicial que releve el consentimiento. </p>
            <p>Principio de finalidad: el uso, captura, recolección y tratamiento de datos personales a los que tenga acceso y sean acopiados y recogidos por WNR, estarán subordinados y atenderán una finalidad legítima, la cual debe serle informada al respectivo titular de los datos personales.</p>
            <p>Principio de veracidad o calidad: la información sujeta a uso, captura, recolección y tratamiento de datos personales debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</p>
            <p>Principio de transparencia: en el uso, captura, recolección y tratamiento de datos personales debe garantizarse el derecho del Titular a obtener de WNR, en cualquier momento y sin restricciones, información acerca de la existencia de cualquier tipo de información o dato personal que sea de su interés o titularidad.</p>
            <p>Principio de acceso y circulación restringida: Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados. Para estos propósitos la obligación de WNR será de medio.</p>
            <p>Principio de seguridad: Los datos personales e información usada, capturada, recolectada y sujeta a tratamiento por WNR, será objeto de protección en la medida en que los recursos técnicos y estándares mínimos así lo permitan, a través de la adopción de medidas tecnológicas de protección, protocolos, y todo tipo de medidas administrativas que sean necesarias para otorgar seguridad a los registros y repositorios electrónicos evitando su adulteración, modificación, pérdida, consulta, y en general en contra de cualquier uso o acceso no autorizado. </p>
            <p>Principio de confidencialidad: Todas y cada una de las personas que administran, manejen, actualicen o tengan acceso a informaciones de cualquier tipo que se encuentre en Bases o Bancos de Datos, se comprometen a conservar y mantener de manera estrictamente confidencial y no revelarla a terceros, todas las informaciones personales, comerciales, contables, técnicas, comerciales o de cualquier otro tipo suministradas en la ejecución y ejercicio de sus funciones. Todas las personas que trabajen actualmente o sean vinculadas a futuro para tal efecto, en la administración y manejo de bases de datos, deberán suscribir un documento adicional u otrosí a su contrato laboral o de prestación de servicios para efectos de asegurar tal compromiso. Esta obligación persiste y se mantiene inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento. </p>
            <br></br>

            <h3>DATOS SENSIBLES</h3>
            <p>Son datos sensibles aquellos que afectan la intimidad del titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos, entre otros, la captura de imagen fija o en movimiento, huellas digitales, fotografías, iris, reconocimiento de voz, facial o de palma de mano, etc. </p>
            <br></br>

            <h3>TRATAMIENTO DE DATOS SENSIBLES</h3>
            <p>Se podrá hacer uso y tratamiento de los datos catalogados como sensibles cuando:</p>
            <p>a.	El Titular haya dado su autorización explícita a dicho tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización.</p>
            <p>b.	El Tratamiento sea necesario para salvaguardar el interés vital del titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización. </p>
            <p>c.	El Tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del titular. </p>
            <p>d.	El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial. </p>
            <p>e.	El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los Titulares.</p>
            <br></br>

            <h3>AUTORIZACIÓN DEL TITULAR</h3>
            <p>Sin perjuicio de las excepciones previstas en la ley, en el tratamiento se requiere la autorización previa, expresa e informada del Titular, la cual deberá ser obtenida por cualquier medio que pueda ser objeto de consulta y verificación posterior. </p>
            <br></br>

            <h3>CASOS EN LOS QUE NO SE REQUIERE LA AUTORIZACIÓN</h3>
            <p>La autorización del Titular no será necesaria cuando se trate de: </p>
            <p>a.	Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial.</p>
            <p>b.	Datos de naturaleza pública.</p>
            <p>c.	Casos de urgencia médica o sanitaria.</p>
            <p>d.	Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos. </p>
            <p>e.	Datos relacionados con el Registro Civil de las Personas. </p>
            <br></br>

            <h3>DERECHOS DE LOS NIÑOS, NIÑAS Y ADOLESCENTES</h3>
            <p>En el Tratamiento se asegurará el respeto a los derechos prevalentes de los menores. Queda proscrito el Tratamiento de datos personales de menores, salvo aquellos datos que sean de naturaleza pública. </p>
            <br></br>

            <h3>DEBERES DE WNR COMO RESPONSABLE DEL TRATAMIENTO DE DATOS PERSOANALES</h3>
            <p>Cuando WNR actúe como Responsable del Tratamiento de datos personales, cumplirá con los siguientes deberes: </p>
            <p>a.	Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data. </p>
            <p>b.	Solicitar y conservar, copia de la respectiva autorización otorgada por el titular. </p>
            <p>c.	Informar debidamente al titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada. </p>
            <p>d.	Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
            <p>e.	Garantizar que la información que se suministre al encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.</p>
            <p>f.	Actualizar la información, comunicando de forma oportuna al encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada.</p>
            <p>g.	Rectificar la información cuando sea incorrecta y comunicar lo pertinente al encargado del tratamiento.</p>
            <p>h.	Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado.</p>
            <p>i.	Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.</p>
            <p>j.	Tramitar las consultas y reclamos formulados. </p>
            <p>k.	Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.</p>
            <p>l.	Informar a solicitud del Titular sobre el uso dado a sus datos.</p>
            <p>m.	Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares. </p>
            <br></br>

            <h3>AUTORIZACIONES Y CONSENTIMIENTO</h3>
            <p>La recolección, almacenamiento, uso, circulación o supresión de datos personales por parte de WNR, requiere del consentimiento libre, previo, expreso e informado del titular de los mismos. </p>
            <br></br>

            <h3>MEDIO Y MANIFESTACIONES PARA OTORGAR LA AUTORIZACIÓN</h3>
            <p>La autorización puede constar en un documento físico, electrónico, mensaje de datos, Internet, Sitios Web, en cualquier otro formato que permita garantizar su posterior consulta, o, mediante un mecanismo técnico o tecnológico idóneo, que permita manifestar u obtener el consentimiento vía clic o doble clic, mediante el cual se pueda concluir de manera inequívoca, que de no haberse surtido una conducta del titular, los datos nunca hubieren sido capturados y almacenados en la base de datos. La autorización será generada por WNR y será puesta a disposición del titular con antelación y de manera previa al tratamiento de sus datos personales.  </p>
            <br></br>

            <h3>PRUEBA DE LA AUTORIZACIÓN</h3>
            <p> WNR utilizará los mecanismos con que cuenta actualmente, e implementará y adoptará las acciones tendientes y necesarias para mantener registros o mecanismos técnicos o tecnológicos idóneos de cuándo y cómo obtuvo autorización por parte de los titulares de datos personales para el tratamiento de los mismos. Para dar cumplimiento a lo anterior, se podrán establecer archivos físicos o repositorios electrónicos realizados de manera directa o a través de terceros contratados para tal fin. </p>
            <p>El Aviso de Privacidad es el documento físico, electrónico o en cualquier otro formato conocido o por conocer, que es puesto a disposición del Titular para el tratamiento de sus datos personales. A través de este documento se informa al Titular la información relativa a la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las características del tratamiento que se pretende dar a los datos personales. </p>
            <br></br>

            <h3>PRERROGATIVAS Y DEMÁS DERECHOS DE LOS TITULARES DE LA INFORMACIÓN</h3>
            <p>En atención y en consonancia con lo dispuesto en la normatividad vigente y aplicable en materia de protección de datos personales, el titular de los datos personales tiene los siguientes derechos:</p>
            <p>a.	Acceder, conocer, rectificar y actualizar sus datos personales frente a WNR, en su condición de responsable del tratamiento. </p>
            <p>b.	Por cualquier medio válido, solicitar prueba de la autorización otorgada a WNR, en su condición de Responsable del Tratamiento. </p>
            <p>c.	A recibir información por parte de WNR, previa solicitud, respecto del uso que le ha dado a sus datos personales.</p>
            <p>d.	Acudir ante las autoridades legalmente constituidas, en especial ante la Superintendencia de Industria y Comercio, y presentar quejas por, infracciones a lo dispuesto en la normatividad vigente en las normas aplicables, previo trámite de consulta o requerimiento ante el Responsable del Tratamiento.</p>
            <p>e.	Modificar y revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales vigentes.</p>
            <p>f.	Tener conocimiento y acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</p>
            <br></br>

            <h3>DEBERES DE WNR EN RELACIÓN CON EL TRATAMIENTO DE LOS DATOS PERSONALES</h3>
            <p>WNR tendrá presente, en todo momento, que los datos personales son propiedad de las personas a las que se refieren y que sólo ellas pueden decidir sobre los mismos. En este sentido, hará uso de ellos sólo para aquellas finalidades para las que se encuentra facultado debidamente, y respetando en todo caso la normatividad vigente sobre protección de datos personales.</p>
            <br></br>

            <h3>GARANTÍAS DEL DERECHO AL ACCESO</h3>
            <p>WNR garantizará el derecho de acceso cuando, previa acreditación de la identidad del Titular, legitimidad, o personalidad de su representante, poniendo a disposición de éste, sin costo o erogación alguna, de manera pormenorizada y detallada, los respectivos datos personales a través de todo tipo de medio, incluyendo los medios electrónicos que permitan el acceso directo del Titular a ellos. Dicho acceso deberá ofrecerse sin límite alguno y le deben permitir al titular la posibilidad de conocerlos y actualizarlos en línea.</p>
            <p>Los canales de atención para el ejercicio de este derecho serán:</p>
            <p>•	Por escrito a través de correo físico: _________ Bogotá – Colombia</p>
            <p>•	Por escrito a través de correo electrónico: _________</p>
            <p>•	Celular: (+57) ________</p>
            <br></br>

            <h3>CONSULTAS</h3>
            <p>Los Titulares, o sus causahabientes podrán consultar la información personal del Titular que repose en cualquier base de datos. En consecuencia, WNR garantizará el derecho de consulta, suministrando a los titulares, toda la información contenida en el registro individual o que esté vinculada con la identificación del Titular. </p>
            <p>Con respecto a la atención de solicitudes de consulta de datos personales, WNR garantiza:</p>
            <p>a.	Habilitar medios de comunicación electrónica u otros que considere pertinentes.</p>
            <p>b.	Establecer formularios, sistemas y otros métodos simplificados, mismos que deben ser informados en el aviso de privacidad.</p>
            <p>c.	Utilizar los servicios de atención al cliente o de reclamaciones que tiene en operación.</p>
            <p>d.	En cualquier caso, independientemente del mecanismo implementado para la atención de solicitudes de consulta, las mismas serán atendidas en un término máximo de diez (10) días hábiles contados a partir de la fecha de su recibo. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado antes del vencimiento de los diez (10) días, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer plazo.</p>
            <br></br>

            <h3>RECLAMOS</h3>
            <p>El Titular o sus causahabientes que consideren que la información contenida en una base de datos debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley, podrán presentar un reclamo ante el Responsable del Tratamiento, canalizándola y remitiéndola a través de la dependencia designada y cuyos datos de contacto se concretan más adelante en el numeral 22 del presente documento y que ejercerá la función de protección de datos personales al interior de WNR. </p>
            <p>El reclamo lo podrá presentar el Titular, teniendo en cuenta la información señalada en el artículo 15 de la Ley 1581 de 2012 y en el Decreto 1377 de 2013, y demás normas que los modifiquen o adicionen.</p>
            <br></br>

            <h3>IMPLEMENTACIÓN DE PROCEDIMIENTOS PARA GARANTIZAR EL DERECHO A PRESENTAR RECLAMOS</h3>
            <p>En cualquier momento y de manera gratuita, el titular o su representante podrán solicitar a personal de WNR la rectificación, actualización o supresión de sus datos personales, previa acreditación de su identidad. Los derechos de rectificación, actualización o supresión únicamente se podrán ejercer por:</p>
            <p>a.	El titular o sus causahabientes, previa acreditación de su identidad, o a través de instrumentos electrónicos que le permitan identificarse.</p>
            <p>b.	Su representante, previa acreditación de la representación.</p>
            <p>c.	Cuando la solicitud sea formulada por persona distinta del titular, deberá acreditarse en debida forma la personería o mandato para actuar; y en caso de no acreditar tal calidad, la solicitud se tendrá por no presentada. La solicitud de rectificación, actualización o supresión debe ser presentada a través de los medios habilitados por WNR señalados en el aviso de privacidad y contener, como mínimo, la siguiente información: </p>
            <p>   i.	El nombre y domicilio del titular o cualquier otro medio para recibir la respuesta.</p>
            <p>   ii.	Los documentos que acrediten la identidad o la personalidad de su representante. </p>
            <p>   iii.	La descripción clara y precisa de los datos personales respecto de los cuales el titular busca ejercer alguno de los derechos.</p>
            <p>   iv.	En caso dado, otros elementos o documentos que faciliten la localización de los datos personales.</p>
            <br></br>

            <h3>RECTIFICACIÓN Y ACTUALIZACIÓN DE DATOS</h3>
            <p>WNR tiene la obligación de rectificar y actualizar a solicitud del Titular, la información de éste que resulte ser incompleta o inexacta, de conformidad con el procedimiento y los términos arriba señaladas. Al respecto se tendrá en cuenta lo siguiente: En las solicitudes de rectificación y actualización de datos personales, el Titular debe indicar las correcciones a realizar y aportar la documentación que avale su petición.</p>
            <p>WNR, tiene plena libertad de habilitar mecanismos que le faciliten el ejercicio de este derecho, siempre y cuando éstos beneficien al titular. En consecuencia, se podrán habilitar medios electrónicos u otros que considere pertinentes. </p>
            <p>WNR, podrá establecer formularios, sistemas y otros métodos simplificados, mismos que deben ser informados en el aviso de privacidad y que se pondrán a disposición de los interesados en la página web.</p>
            <br></br>

            <h3>SUPRESIÓN DE DATOS</h3>
            <p>El titular tiene el derecho, en todo momento, a solicitar a WNR la supresión (eliminación) de sus datos personales cuando:</p>
            <p>a.	Considere que los mismos no están siendo tratados conforme a los principios, deberes y obligaciones previstas en la normatividad vigente. </p>
            <p>b.	Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron recabados.</p>
            <p>c.	Se haya superado el periodo necesario para el cumplimiento de los fines para los que fueron recabados Esta supresión implica la eliminación total o parcial de la información personal de acuerdo con lo solicitado por el titular en los registros, archivos, bases de datos o tratamientos realizados por WNR</p>
            <p>d.	Es importante tener en cuenta que el derecho de cancelación no es absoluto y el responsable puede negar el ejercicio de este cuando:</p>
            <p>   i.	El Titular tenga un deber legal o contractual de permanecer en la base de datos.</p>
            <p>   ii.	La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</p>
            <p>   iii.	Los datos sean necesarios para proteger los intereses jurídicamente tutelados del titular, para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular. </p>
            <br></br>

            <h3>REVOCATORIA DE LA AUTORIZACIÓN</h3>
            <p>Los Titulares de los datos personales pueden revocar el consentimiento al tratamiento de sus datos personales en cualquier momento, siempre y cuando no lo impida una disposición legal o contractual. Para ello, WNR deberá establecer mecanismos sencillos y gratuitos que permitan al titular revocar su consentimiento, al menos por el mismo medio por el que lo otorgó. Se deberá tener en cuenta que existen dos modalidades en las que la revocación del consentimiento puede darse:</p>
            <p>a.	La primera, puede ser sobre la totalidad de las finalidades consentidas, esto es, WNR deba dejar de tratar por completo los datos del titular;</p>
            <p>b.	La segunda, puede ocurrir sobre tipos de tratamiento determinados, como por ejemplo para fines publicitarios o de estudios de mercado. Con la segunda modalidad, esto es, la revocación parcial del consentimiento, se mantienen a salvo otros fines del tratamiento que el responsable, de conformidad con la autorización otorgada puede llevar a cabo y con los que el titular está de acuerdo.</p>
            <br></br>

            <h3>SEGURIDAD DE LA INFORMACIÓN Y MEDIDAS DE SEGURIDAD</h3>
            <p>En desarrollo del principio de seguridad establecido en la normatividad vigente, WNR adoptará las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. </p>
            <br></br>

            <h3>UTILIZACIÓN Y TRANSFERENCIA INTERNACIONAL DE DATOS PERSONALES E INFORMACIÓN PERSONAL POR PARTE DE WNR</h3>
            <p>Dependiendo de la naturaleza de las relaciones permanentes u ocasionales que cualquier persona titular de datos personales pueda tener con WNR, la totalidad de su información puede ser transferida al exterior, sujeto a los requerimientos legales aplicables, con la aceptación de la presente política, autoriza expresamente para transferir Información Personal. La información será transferida para todas las relaciones que puedan establecerse con WNR. Sin perjuicio de la obligación de observar y mantener la confidencialidad de la información, WNR tomará las medidas necesarias para que esos terceros conozcan y se comprometan a observar esta Política, bajo el entendido de que la información personal que reciban, únicamente podrá ser utilizada para asuntos directamente relacionados con la relación propia de la relación con WNR, y solamente mientras ésta dure, y no podrá ser usada o destinada para propósito o fin diferente.</p>
            <p>WNR también puede intercambiar Información Personal con autoridades gubernamentales o públicas de otro tipo (incluidas, entre otras autoridades judiciales o administrativas, autoridades fiscales y organismos de investigación penal, civil, administrativa, disciplinaria y fiscal), y terceros participantes en procedimientos legales civiles y sus contadores, auditores, abogados y otros asesores y representantes, porque es necesario o apropiado: (a) para cumplir con las leyes vigentes, incluidas las leyes distintas a las de su país de residencia; (b) para cumplir con procesos jurídicos; (c) para responder las solicitudes de las autoridades públicas y del gobierno, y para responder las solicitudes de las autoridades públicas y del gobierno distintas a las de su país de residencia; (d) para hacer cumplir nuestros términos y condiciones; (e) para proteger nuestras operaciones; (f) para proteger nuestros derechos, privacidad, seguridad o propiedad, los suyos o los de terceros; y (g) obtener las indemnizaciones aplicables o limitar los daños y perjuicios que nos puedan afectar. </p>
            <br></br>

            <h3>PROTECCIÓN DE DATOS PERSONALES AL INTERIOR DE WNR</h3>
            <p>WNR, en los términos establecidos en la normatividad vigente, actuará como RESPONSABLE DEL TRATAMIENTO de Datos Personales. El área de tecnología queda designada como la que recibirá, procesará y tramitará las solicitudes de los titulares, en los términos, plazos y condiciones establecidos por la normatividad vigente, para el ejercicio de los derechos de acceso, consulta, rectificación, actualización, supresión y revocatoria a que se refiere la normatividad vigente sobre protección de datos personales. En el evento en que usted considere que WNR dio un uso contrario al autorizado y a las leyes aplicables, podrá contactarnos a través de una comunicación motivada dirigida al correo electrónico ___________________</p>
            <p>El presente manual rige a partir del __ de ___ de 2022.</p>
            <br></br>


            </div>
        </DialogLayout>
    )
}
