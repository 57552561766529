import React, { useContext, useEffect, useState } from 'react' 
import {
    Grid, TextField, FormControlLabel, Container, Checkbox, Divider,
    MenuItem, FormControl, InputLabel, Select, Button, FormHelperText
} from '@material-ui/core'
import { getCountries } from '../../../data'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DataContext } from '../../../context/DataContext';
import TextFieldPassword from '../../base/TextFieldPassword/TextFieldPassword';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'; 
import TermAndConditionModal from '../../modals/TermAndConditionModal';
import PrivacyPolicyModal from '../../modals/PrivacyPolicyModal';
import TermAndConditionModalEn from '../../modals/TermAndConditionModalEn';
import PrivacyPolicyModalEn from '../../modals/PrivacyPolicyModalEn';
import { MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD } from '../../../contants/globals';

export default function CabinForm({ option, setOption, setShowCabinForm, handleOpen }) {
    const { t } = useTranslation();
    // const { language } = useContext(I18nextContext);
    const [openTC, setOpenTC] = useState(false);
    const [openPP, setOpenPP] = useState(false);
    const [countries, setCountries] = useState([]);
    const { booking, setBooking, setCustomer, setOptionForm } = useContext(DataContext);
    // const [loading, setLoading] = useState(false);

    const handleCloseTC = () => setOpenTC(false);
    const handleOpenTC = () => setOpenTC(true);
    const handleClosePP = () => setOpenPP(false);
    const handleOpenPP = () => setOpenPP(true);

    useEffect(() => {
        getCountries()
            .then(data => {
                setCountries(data);
            })
            .catch(console.log)
    }, [])

    const getInitialValues = () => {
        switch (option) {
            case "Guest":
                return {
                    cust_firstname: '',
                    cust_lastname: '',
                    cust_email: '',
                    cust_country_id: '',
                    book_password: '',
                    book_repeat_password: '',
                    termsConditions: false,
                    privacyPolicy: false,
                }
            default:
                return {
                    book_password: '',
                    book_repeat_password: '',
                    termsConditions: false,
                    privacyPolicy: false,
                }
        }
    }

    const getValidationSchema = () => {
        switch (option) {
            case "Guest":
                return {
                    cust_firstname: Yup
                        .string()
                        .required(t('Validation Firstnames')),
                    cust_lastname: Yup
                        .string()
                        .required(t('Validation Lastnames')),
                    cust_country_id: Yup
                        .string()
                        .required(t('Validation Country')),
                    cust_email: Yup
                        .string()
                        .required(t('Validation Email'))
                        .email(t('Validation Invalid Email')),
                    book_password: Yup.string()
                        .required(t('Validation Password'))
                        .oneOf([Yup.ref("book_repeat_password")], t('Validation Password Not Equal'))
                        .min(MIN_LENGTH_PASSWORD, t('Validation Password Min'))
                        .max(MAX_LENGTH_PASSWORD, t('Validation Password Max')),
                    book_repeat_password: Yup.string()
                        .required(t('Validation Password'))
                        .oneOf([Yup.ref("book_password")], t('Validation Password Not Equal')),
                    termsConditions: Yup
                        .boolean()
                        .oneOf([true], t('Validation Terms Conditions')),
                    privacyPolicy: Yup
                        .boolean()
                        .oneOf([true], t('Validation Policy Privacy')),
                }
            default:
                return {
                    book_password: Yup.number(t('Validation Password Is Numeric'))
                        .typeError(t('Validation Password Is Numeric'))
                        .required(t('Validation Password'))
                        .oneOf([Yup.ref("book_repeat_password")], t('Validation Password Not Equal'))
                        .test('lenmore', t('Validation Password Min'), val => String(val).length >= MIN_LENGTH_PASSWORD)
                        .test('len', t('Validation Password Max'), val => String(val).length <= MAX_LENGTH_PASSWORD),
                    book_repeat_password: Yup.number(t('Validation Password Is Numeric'))
                        .typeError(t('Validation Password Is Numeric'))
                        .required(t('Validation Password'))
                        .oneOf([Yup.ref("book_password")], t('Validation Password Not Equal')),
                    termsConditions: Yup
                        .boolean()
                        .oneOf([true], t('Validation Terms Conditions')),
                    privacyPolicy: Yup
                        .boolean()
                        .oneOf([true], t('Validation Policy Privacy')),
                }
        }
    }

    const formik = useFormik({
        initialValues: getInitialValues(option),
        validationSchema: Yup.object(getValidationSchema(option)),
        onSubmit: async (formData) => {
            console.log(formData);
            const { cust_firstname, cust_lastname, cust_country_id, cust_email, book_password } = formData;
            const _booking = { ...booking }
            let customer = {};
            // let token=null;
            if (option === 'Guest') {
                customer = { cust_firstname, cust_lastname, cust_country_id, cust_email }
            } 
            _booking.book_password = book_password;
            setBooking(_booking);
            setOptionForm(option);
            setCustomer(customer);
            handleOpen();
            // const {book_base_id,book_duration_hours,book_duration_minutes,book_end_datetime,book_init_datetime,book_total,book_password,base}=booking;
        }

    })

    const goToRegister = () => {
        setOption('Register');
        setShowCabinForm(false);
    }
    return t("Lenguaje") == "es" ? (
        <>
            <div className='cabin-form'>
                <Container>
                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <Grid className='form-grid' container spacing={3} justify='center'>
                            {option === 'Guest' && <>
                                <Grid item xs={12}>
                                    <h1>{t('Your Information')}</h1>
                                    <br></br>
                                    <p style={{ textAlign: 'center' }}>{t('Do not have an account?')} <a href='#' onClick={goToRegister}>{t('Register')}</a></p>
                                    <br></br>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="cust_firstname"
                                        label={t('Form Firstname')}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={formik.handleChange}
                                        value={formik.values.cust_firstname}
                                        error={formik.touched.cust_firstname && formik.errors.cust_firstname}
                                        helperText={formik.touched.cust_firstname ? formik.errors.cust_firstname : ''}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="cust_lastname"
                                        label={t('Form Lastname')}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={formik.handleChange}
                                        value={formik.values.cust_lastname}
                                        error={formik.touched.cust_lastname && formik.errors.cust_lastname}
                                        helperText={formik.touched.cust_lastname ? formik.errors.cust_lastname : ''}
                                        fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl size={'small'} variant="outlined" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">{t('Form Country')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="cust_country_id"
                                            label={t('Form Country')}
                                            onChange={(event, child) => formik.setFieldValue('cust_country_id', event.target.value)}
                                            value={formik.values.cust_country_id}
                                            error={formik.touched.cust_country_id && formik.errors.cust_country_id}
                                            helperText={formik.touched.cust_country_id ? formik.errors.cust_country_id : ''}
                                        >
                                            <MenuItem value="">
                                                <em>{t('None')}</em>
                                            </MenuItem>
                                            {
                                                countries.map((item, index) => (
                                                    <MenuItem key={index} value={item.coun_id}>{`${item.coun_name}`}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12} md={6}>
                                    <TextField
                                        id="cust_email"
                                        label={t('Form Email')}
                                        variant="outlined"
                                        size={'small'}
                                        type="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.cust_email}
                                        error={formik.touched.cust_email && formik.errors.cust_email}
                                        helperText={formik.touched.cust_email ? formik.errors.cust_email : ''}
                                        fullWidth />
                                </Grid>
                                <Grid container item xs={12}>
                                    <br></br>
                                </Grid>
                            </>}
                            <Grid item xs={12}>
                                <Divider />
                                <br></br>
                            </Grid>
                            <Grid item xs={12}>
                                <h1>{t('Cabin Access')}</h1>
                                <br></br>
                                <p>{t('Cabin Access Message')}</p>
                                <br></br>
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                <TextFieldPassword
                                    id="book_password"
                                    label={t('Form Cabin Password')}
                                    variant="outlined"
                                    type={'password'}
                                    labelWidth={120}
                                    size={'small'}
                                    onChange={(e)=>{
                                        const reg = new RegExp('^[0-9]+$'); 
                                        if(reg.test(e.target.value)||e.target.value==''){
                                            formik.handleChange(e);
                                        } 
                                    }}
                                    value={formik.values.book_password}
                                    error={formik.touched.book_password && formik.errors.book_password}
                                    helperText={formik.touched.book_password ? formik.errors.book_password : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>  
                                <TextFieldPassword
                                    id="book_repeat_password"
                                    label={t('Form Confirm Cabin Password')}
                                    variant="outlined"
                                    type={'password'} 
                                    labelWidth={180}
                                    size={'small'}
                                    onChange={(e)=>{
                                        const reg = new RegExp('^[0-9]+$'); 
                                        if(reg.test(e.target.value)||e.target.value==''){
                                            formik.handleChange(e);
                                        } 
                                    }}
                                    value={formik.values.book_repeat_password}
                                    error={formik.touched.book_repeat_password && formik.errors.book_repeat_password}
                                    helperText={formik.touched.book_repeat_password ? formik.errors.book_repeat_password : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={formik.handleChange}
                                                checked={formik.values.termsConditions}
                                                name="termsConditions"
                                                color="primary"
                                            />
                                        }
                                        label={<>{t('Cabin Access Term and Conditions 1')}
                                            <a href='#' onClick={handleOpenTC} style={{ textDecoration: 'underline', color: '#E15311' }}>{t('Cabin Access Term and Conditions 2')}</a></>}

                                    />
                                    <FormHelperText
                                        id="component-helper-text-1"
                                        error>
                                        {formik.touched.termsConditions ? formik.errors.termsConditions : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12}>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                // checked={state.checkedB}
                                                onChange={formik.handleChange}
                                                checked={formik.values.privacyPolicy}
                                                name="privacyPolicy"
                                                color="primary"
                                            />
                                        }
                                        label={<>{t('Cabin Access Privacy policy 1')}
                                        <a href='#' onClick={handleOpenPP} style={{ textDecoration: 'underline', color: '#E15311' }}>{t('Cabin Access Privacy policy 2')}</a>
                                        </>}
                                    />
                                    <FormHelperText
                                        id="component-helper-text-2"
                                        error>
                                        {formik.touched.privacyPolicy ? formik.errors.privacyPolicy : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} justify={'center'}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    style={{ minWidth: 250 }}
                                    type='submit'
                                >
                                    {`${t('Pay')} $${booking?.book_total}`}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </div>
       

            <TermAndConditionModal
                open={openTC}
                handleClose={handleCloseTC}
            />
            <PrivacyPolicyModal
                open={openPP}
                handleClose={handleClosePP}
            />
        </>
    ) : (
        <>
            <div className='cabin-form'>
                <Container>
                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <Grid className='form-grid' container spacing={3} justify='center'>
                            {option === 'Guest' && <>
                                <Grid item xs={12}>
                                    <h1>{t('Your Information')}</h1>
                                    <br></br>
                                    <p style={{ textAlign: 'center' }}>{t('Do not have an account?')} <a href='#' onClick={goToRegister}>{t('Register')}</a></p>
                                    <br></br>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="cust_firstname"
                                        label={t('Form Firstname')}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={formik.handleChange}
                                        value={formik.values.cust_firstname}
                                        error={formik.touched.cust_firstname && formik.errors.cust_firstname}
                                        helperText={formik.touched.cust_firstname ? formik.errors.cust_firstname : ''}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="cust_lastname"
                                        label={t('Form Lastname')}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={formik.handleChange}
                                        value={formik.values.cust_lastname}
                                        error={formik.touched.cust_lastname && formik.errors.cust_lastname}
                                        helperText={formik.touched.cust_lastname ? formik.errors.cust_lastname : ''}
                                        fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl size={'small'} variant="outlined" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">{t('Form Country')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="cust_country_id"
                                            label={t('Form Country')}
                                            onChange={(event, child) => formik.setFieldValue('cust_country_id', event.target.value)}
                                            value={formik.values.cust_country_id}
                                            error={formik.touched.cust_country_id && formik.errors.cust_country_id}
                                            helperText={formik.touched.cust_country_id ? formik.errors.cust_country_id : ''}
                                        >
                                            <MenuItem value="">
                                                <em>{t('None')}</em>
                                            </MenuItem>
                                            {
                                                countries.map((item, index) => (
                                                    <MenuItem key={index} value={item.coun_id}>{`${item.coun_name}`}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12} md={6}>
                                    <TextField
                                        id="cust_email"
                                        label={t('Form Email')}
                                        variant="outlined"
                                        size={'small'}
                                        type="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.cust_email}
                                        error={formik.touched.cust_email && formik.errors.cust_email}
                                        helperText={formik.touched.cust_email ? formik.errors.cust_email : ''}
                                        fullWidth />
                                </Grid>
                                <Grid container item xs={12}>
                                    <br></br>
                                </Grid>
                            </>}
                            <Grid item xs={12}>
                                <Divider />
                                <br></br>
                            </Grid>
                            <Grid item xs={12}>
                                <h1>{t('Cabin Access')}</h1>
                                <br></br>
                                <p>{t('Cabin Access Message')}</p>
                                <br></br>
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                <TextFieldPassword
                                    id="book_password"
                                    label={t('Form Cabin Password')}
                                    variant="outlined"
                                    type={'password'}
                                    labelWidth={120}
                                    size={'small'}
                                    onChange={(e)=>{
                                        const reg = new RegExp('^[0-9]+$'); 
                                        if(reg.test(e.target.value)||e.target.value==''){
                                            formik.handleChange(e);
                                        } 
                                    }}
                                    value={formik.values.book_password}
                                    error={formik.touched.book_password && formik.errors.book_password}
                                    helperText={formik.touched.book_password ? formik.errors.book_password : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>  
                                <TextFieldPassword
                                    id="book_repeat_password"
                                    label={t('Form Confirm Cabin Password')}
                                    variant="outlined"
                                    type={'password'} 
                                    labelWidth={180}
                                    size={'small'}
                                    onChange={(e)=>{
                                        const reg = new RegExp('^[0-9]+$'); 
                                        if(reg.test(e.target.value)||e.target.value==''){
                                            formik.handleChange(e);
                                        } 
                                    }}
                                    value={formik.values.book_repeat_password}
                                    error={formik.touched.book_repeat_password && formik.errors.book_repeat_password}
                                    helperText={formik.touched.book_repeat_password ? formik.errors.book_repeat_password : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={formik.handleChange}
                                                checked={formik.values.termsConditions}
                                                name="termsConditions"
                                                color="primary"
                                            />
                                        }
                                        label={<>{t('Cabin Access Term and Conditions 1')}
                                            <a href='#' onClick={handleOpenTC} style={{ textDecoration: 'underline', color: '#E15311' }}>{t('Cabin Access Term and Conditions 2')}</a></>}

                                    />
                                    <FormHelperText
                                        id="component-helper-text-1"
                                        error>
                                        {formik.touched.termsConditions ? formik.errors.termsConditions : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12}>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                // checked={state.checkedB}
                                                onChange={formik.handleChange}
                                                checked={formik.values.privacyPolicy}
                                                name="privacyPolicy"
                                                color="primary"
                                            />
                                        }
                                        label={<>{t('Cabin Access Privacy policy 1')}
                                        <a href='#' onClick={handleOpenPP} style={{ textDecoration: 'underline', color: '#E15311' }}>{t('Cabin Access Privacy policy 2')}</a>
                                        </>}
                                    />
                                    <FormHelperText
                                        id="component-helper-text-2"
                                        error>
                                        {formik.touched.privacyPolicy ? formik.errors.privacyPolicy : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} justify={'center'}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    style={{ minWidth: 250 }}
                                    type='submit'
                                >
                                    {`${t('Pay')} $${booking?.book_total}`}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </div>
       

            <TermAndConditionModalEn
                open={openTC}
                handleClose={handleCloseTC}
            />
            <PrivacyPolicyModalEn
                open={openPP}
                handleClose={handleClosePP}
            />
        </>
    )
}
