import React, { useEffect, useState } from 'react'
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PaymentNavbar from '../components/../components/checkout/PaymentNavbar'
import LoginForm from '../components/../components/checkout/LoginForm'
import RegisterForm from '../components/../components/checkout/RegisterForm'
import CabinForm from '../components/../components/checkout/CabinForm'
import { Divider } from '@material-ui/core'
import CardPaymentModal from '../components/modals/CardPaymentModal'

export default function PaymentForm({ location }) {
  const [option, setOption] = useState(location.state?.option || '')
  const [showCabinForm, setShowCabinForm] = useState(false)
  const [showCardPaymentModal, setshowCardPaymentModal] = useState(false)

  const handleOpen = () => setshowCardPaymentModal(true);
  const handleClose = () => setshowCardPaymentModal(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (option === 'Guest') setShowCabinForm(true);
  }, [option])

  if (!option) {
    if (typeof window !== 'undefined') {
      navigate('/reserve');
    }
    return <></>
  };

  const getFormByOption = (option) => {
    switch (option) {
      case 'Login': return <LoginForm setShowCabinForm={setShowCabinForm} setOption={setOption} />
      case 'Register': {
        // setShoCabinForm(true);
        return <RegisterForm setShowCabinForm={setShowCabinForm} setOption={setOption} />
      }
      // case 'Guest': {
      //   // setShoCabinForm(true);
      //   return <GuestForm setShowCabinForm={setShowCabinForm}/>
      // }
      default:
        return <></>
    }
  }

  return (
    <MainLayout withMargin>
      <Seo title={t('Payment Form')} />
      <PaymentNavbar />
      <Divider />
      {getFormByOption(option)}
      {showCabinForm &&
        <>
          <CabinForm option={option} setOption={setOption} setShowCabinForm={setShowCabinForm} handleOpen={handleOpen} />
        </>
      }
      <br></br>
      <br></br>
      {showCardPaymentModal && <CardPaymentModal open={showCardPaymentModal} handleClose={handleClose} />}
    </MainLayout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;