import React, { useContext, useEffect, useState } from 'react'
import { Grid, Button, InputAdornment, FormControl, OutlinedInput, CircularProgress } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LockIcon from '@material-ui/icons/Lock';
import { DataContext } from '../../../../context/DataContext';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';
import * as Storage from '../../../../utils/storage'
import {
    ElementsConsumer, CardNumberElement, CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import StripeInput from '../../../base/StripeInput';
import { createTransactionBraintree } from '../../../../data';

const inputStyle = {
    iconColor: '#c4f0ff',
    color: '#000',
    fontWeight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
        color: '#B9B8B1',
    },
}

export default function CardPaymentForm({ setShowErrorMessage, setError }) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (<CheckoutForm stripe={stripe} elements={elements} setShowErrorMessage={setShowErrorMessage} setError={setError} />)}
        </ElementsConsumer>
    )
}

const CheckoutForm = ({ stripe, elements, setShowErrorMessage, setError }) => {
    const { t } = useTranslation();
    const { language } = useContext(I18nextContext);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);
    const { booking, optionForm, customer, cabinTypeId } = useContext(DataContext);

    // useEffect(() => {
    //     CardNumberElement.on('change', function (event) {
    //         var displayError = document.getElementById('card-errors');
    //         if (event.error) {
    //             displayError.textContent = event.error.message;
    //         } else {
    //             displayError.textContent = '';
    //         }
    //     });
    // }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) return;
        //stripe
        // const cardElement = elements.getElement(CardNumberElement);

        // if (cardElement == null) return;

        // const result = await stripe.createToken(cardElement, { name });

        // if (result.error) return setShowErrorMessage(true)

        const cardElement = elements.getElement(CardNumberElement);

        if (cardElement == null) return; 

        if (!name) {
            setShowErrorMessage(true);
            setError({ title: t('Fill in all fields'), message: t('Fill in all fields Message') });
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name,
            },
        });

        if (result.error) {
            setShowErrorMessage(true);
            setError({ title: t('Fill in all fields'), message: t('Fill in all fields Message') });
        }

        //console.log(result,'stripe'); 

        const option = optionForm;
        const token = option === 'Guest' ? null : Storage.getItem(Storage.TOKEN_USER);

        try {
            let booking2 = booking;
            let aD = new Date(booking2.book_init_datetime);
            let bD = new Date(booking2.book_end_datetime);

            let aE = aD.toLocaleString();
            let bE = bD.toLocaleString();

            let aTime = aD.getTime();
            let bTime = bD.getTime();

            let aNew = new Date(aTime + 18000000);
            let bNew = new Date(bTime + 18000000);

            console.log(aNew);
            console.log(bNew);

            let fechaA = aNew.getFullYear() + "-" + ((aNew.getMonth() + 1) < 10 ? "0" + (aNew.getMonth() + 1) : (aNew.getMonth() + 1)) + "-" +  ((aNew.getDate()) < 10 ? "0" + (aNew.getDate()) : (aNew.getDate())) + "T" + ((aNew.getHours()) < 10 ? "0" + (aNew.getHours()) : (aNew.getHours())) + ":" + ((aNew.getMinutes()) < 10 ? "0" + (aNew.getMinutes()) : (aNew.getMinutes())) + ":00.000Z";
            let fechaB = bNew.getFullYear() + "-" + ((bNew.getMonth() + 1) < 10 ? "0" + (bNew.getMonth() + 1) : (bNew.getMonth() + 1)) + "-" +  ((bNew.getDate()) < 10 ? "0" + (bNew.getDate()) : (bNew.getDate())) + "T" + ((bNew.getHours()) < 10 ? "0" + (bNew.getHours()) : (bNew.getHours())) + ":" + ((bNew.getMinutes()) < 10 ? "0" + (bNew.getMinutes()) : (bNew.getMinutes())) + ":00.000Z";

            booking2.book_init_datetime = fechaA;
            booking2.book_end_datetime = fechaB;

            console.log("entrando1")
            console.log(booking);
            const dataTransaction = await createTransactionBraintree({
                nonce: result.paymentMethod.id,
                amount: booking?.book_total,
                customer,
                booking: booking2,
                option,
                token,
                cabinTypeId: cabinTypeId,
            }
            );
            navigate(language === 'en' ? `/${language}/success-payment` : `/success-payment`, { state: { data: dataTransaction } });
            // console.log(dataTransaction);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            setShowErrorMessage(true);
            if (err?.response?.data?.code) {
                switch (err?.response?.data?.code) {
                    case 1:
                        setError({ title: t('Email Already Exists'), message: t('Email Already Exists Message') })
                        break;
                    case 2:
                        setError({ title: t('No Cabins Available'), message: t('No Cabins Available Message') })
                        break;
                    default:
                        setError({ title: t('Payment Not Completed'), message: t('Payment Not Completed Message') })
                        break;
                }
            } else {
                setError({ title: t('There were problems'), message: t('There were problems Message') })
            }
        }
    };

    return (<form
        onSubmit={handleSubmit}
        id="cardForm"
        className='form-payment'
        autoComplete='off'
    >
        <Grid container spacing={2}>
            <Grid container item xs={12}>
                <FormControl
                    variant="outlined"
                    size={'small'}
                    fullWidth
                >
                    <OutlinedInput
                        aria-describedby="outlined-weight-helper-text"
                        startAdornment={<InputAdornment position="start"><CreditCardIcon /></InputAdornment>}
                        labelWidth={0}
                        inputComponent={StripeInput}
                        fullWidth
                        // error={fields?.number?.isValid === false}
                        inputProps={{
                            component: MyCardNumberElement
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid container item xs={6}>
                <FormControl
                    variant="outlined"
                    size={'small'}
                    fullWidth
                >
                    <OutlinedInput
                        aria-describedby="outlined-weight-helper-text"
                        startAdornment={<InputAdornment position="start"><DateRangeIcon /></InputAdornment>}
                        labelWidth={0}
                        inputComponent={StripeInput}
                        fullWidth
                        // error={fields?.expirationDate?.isValid === false}
                        inputProps={{
                            component: MyCardExpiryElement
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid container item xs={6}>
                <FormControl
                    variant="outlined"
                    size={'small'}
                    fullWidth
                >
                    <OutlinedInput
                        aria-describedby="outlined-weight-helper-text"
                        startAdornment={<InputAdornment position="start"><LockIcon /></InputAdornment>}
                        labelWidth={0}
                        inputComponent={StripeInput}
                        fullWidth
                        // error={fields?.cvv?.isValid === false}
                        inputProps={{
                            component: MyCardCvcElement
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid container item xs={12}>
                <FormControl
                    variant="outlined"
                    size={'small'}
                    fullWidth
                >
                    <OutlinedInput
                        id="cardholder-name"
                        aria-describedby="outlined-weight-helper-text"
                        startAdornment={<InputAdornment position="start"><AccountCircle /></InputAdornment>}
                        labelWidth={0}
                        fullWidth
                        value={name || ''}
                        onChange={e => setName(e.target.value)}
                        placeholder={t('Form CardHolder Name')}
                        error={name === ''}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    type="submit"
                    disabled={loading}
                >
                    {!loading && `${t('Pay')} $${booking?.book_total}`}
                    {loading && <CircularProgress color='inherit' size={20} />}
                </Button>
            </Grid>
        </Grid>
    </form>);
}

const MyCardNumberElement = React.memo((props) => {
    const { t } = useTranslation();
    return <CardNumberElement {...props} options={{
        placeholder: t('Form Card Number'),
        style: {
            base: inputStyle,
        },

    }} />
})

const MyCardExpiryElement = React.memo((props) => {
    return <CardExpiryElement {...props} options={{
        style: {
            base: inputStyle,
        }
    }} />
})

const MyCardCvcElement = React.memo((props) => {
    return <CardCvcElement {...props} options={{
        style: {
            base: inputStyle,
        }
    }} />
})