import React, { useEffect, useState } from 'react' 
import { Grid, TextField, Button, Container, CircularProgress } from '@material-ui/core'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { auth } from '../../../data';
import * as Storage from '../../../utils/storage';
import {toastError} from '../../../utils/toast';
import TextFieldPassword from '../../base/TextFieldPassword/TextFieldPassword';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD } from '../../../contants/globals';

export default function LoginForm({ setShowCabinForm ,setOption}) {
    const { t } = useTranslation();
    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => { 
         if(Storage.getItem(Storage.TOKEN_USER)){
            setIsLogged(true);
            setShowCabinForm(true);
         }
    }, [])

    const formik = useFormik({
        initialValues: {
            user_username: '',
            user_password: '',
        },
        validationSchema: Yup.object({
            user_username: Yup
                .string()
                .required(t('Validation Email'))
                .email(t('Validation Invalid Email')),
            user_password: Yup
                .string()
                .required(t('Validation Password'))
                .min(MIN_LENGTH_PASSWORD, t('Validation Password Min'))
                .max(MAX_LENGTH_PASSWORD, t('Validation Password Max'))
        }),
        onSubmit: async (formData) => {
            search(formData);
        }

    })

    const search = (data) => {
        setLoading(true);
        auth(data)
            .then(value => {
                 console.log(value);
                 Storage.saveItem(Storage.TOKEN_USER,value.token);
                 Storage.saveItem(Storage.EMAIL_AUTH,value.cust_email);
                 Storage.saveItem(Storage.FULLNAMES_AUTH,value.cust_fullnames);
                 Storage.saveItem(Storage.COUNTRY_AUTH,value.country.coun_name);
                 setShowCabinForm(true);
                 setIsLogged(true);
            })
            .catch(error => {
                console.log(error.message);
                toastError(t('Validation Credentials Wrong'));
            })
            .finally(() => setLoading(false))
    }

    const logout=()=>{
        Storage.clearAll();
        setIsLogged(false);
        setShowCabinForm(false);
        setOption('Login');
    }

    const goToGuest=()=>{
        setOption('Guest');
        setShowCabinForm(true);
    };

    return (
        <>
        {!isLogged&&<div  
        className='login-form'>
            <Container>
                <h1>{t('Login')}</h1>
                <br></br>
                <p>{t('Login Not Registered?')}<a src='#' className='pointer' onClick={goToGuest}>{t('Login Continue As Guest')}</a></p>
                <br></br>
                <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Grid className='form-grid' container spacing={3} justify='center'>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="user_username"
                                label={t('Form Email')}
                                variant="outlined"
                                size={'small'}
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.user_username}
                                error={formik.touched.user_username && formik.errors.user_username}
                                helperText={formik.touched.user_username?formik.errors.user_username:''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}> 
                            <TextFieldPassword
                                id="user_password"
                                label={t('Form Password')}
                                variant="outlined"
                                type={'password'}
                                labelWidth={80}
                                size={'small'}
                                onChange={formik.handleChange}
                                value={formik.values.user_password}
                                error={formik.touched.user_password && formik.errors.user_password}
                                helperText={formik.touched.user_password?formik.errors.user_password:''}
                                fullWidth
                            />
                        </Grid>
                        <Grid container item xs={12} justify='center'>
                            <Button
                                color='primary'
                                variant='contained'
                                type='submit'
                                disabled={loading}
                            > 
                                {!loading && t('Login')}
                                {loading && <CircularProgress color='inherit' size={20} />}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>}
        {isLogged&&<div className='login-info'>
            <Container>
                <h1>{t('Login Logged')}</h1> 
                <br></br>
                <p><strong>{Storage.getItem(Storage.FULLNAMES_AUTH)}</strong></p>
                <p>{Storage.getItem(Storage.EMAIL_AUTH)}</p>
                <p>{Storage.getItem(Storage.COUNTRY_AUTH)}</p>
                <br></br>
                <p>{t('Login Not you?')} <a src='#' onClick={logout}>{t('Logout')}</a></p>
            </Container>
        </div>}
        </>
    )
}
