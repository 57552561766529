import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import DialogLayout from '../../../layouts/DialogLayout'
import './TermAndConditionModal.scss'

export default function TermAndConditionModalEn({ open, handleClose }) {
    const { t } = useTranslation();
    return (
        <DialogLayout
            title={t('Term and Conditions')}
            open={open}
            handleClose={handleClose}
            maxWidth='lg'
        >
            <div className='content-term-and-conditions'>
            <h3>1. IDENTIFICATION OF THE PARTIES</h3>
            <p>These General Contracting Clauses are established, on the one hand, by TIVAT COLOMBIA S.A.S, represented by Duilio Patricio Sanguineti identified with US PASSPORT No. 642480171 (hereinafter, 'Wait N Rest'). And, on the other hand, these General Contracting Clauses are accepted by the user, client (hereinafter, the 'Client').</p>
            <p>These Terms and Conditions of service regulate the contractual relationship between the users (hereinafter, 'Users'), with TIVAT COLOMBIA SAS (NIT: 9015044725), hereinafter 'Wait N Rest'. The Users will be subject to the respective General Terms and Conditions, together with all other policies and principles that govern Wait N Rest and that are incorporated herein by reference.</p>
            <p>Wait N' Rest is a service that allows clients to use a room for rest during a time determined by the Client, which will not exceed eight (8) continuous hours for in-person registration and up to 10 hours for web registration.</p>
            <p>The parties understand and agree that there are monitoring and control authorities that have the power to inspect the room at any time and for any reason, without prior notice and without requiring the Client's authorization.</p>
            <p>Wait N' Rest reserves the right to modify this regulation without prior notice to the Client.</p>
            <br></br>

            <h3>2. CONTENT REPRODUCTION</h3>
            <p>Unless otherwise indicated, the reproduction, communication, modification or distribution, in whole or in part, by any means of public or private dissemination, of the contents of the Wait N Rest website and application is expressly prohibited without the express authorization of Wait N Rest, the holder of the intellectual property rights.</p>
            <p>Such acts on the contents or elements that make up the website and application will only be possible through a contract signed between the interested party and Wait N Rest and under the terms and conditions stipulated therein, reserving Wait N Rest the exercise of whatever actions are legally relevant to defend its intellectual property rights, which the Client recognizes, understands, and accepts are for the exclusive use of Wait N Rest.</p>
            <br></br>

            <h3>3. SERVICES</h3>
            <p>Wait N Rest offers a hourly charging rest room service located within the El Dorado International Airport international dock, exclusively intended for resting for the time that the client requires, and in any case not exceeding 8 consecutive hours (hereinafter, the 'Maximum Time'), in rooms equipped with air conditioning, lighting control, temperature regulation, purchase of products from the Wait N Rest virtual store, flight information consulting and WiFi access.</p>
            <p>Once the service is contracted, either through our website or directly at the airport location, the Client will receive a confirmation email indicating the city, airport, terminal, check-in and check-out dates and times, length of stay, room entry code, and the value of the contracted service. The service contracted by the Client is personal and non-transferable and is valid exclusively for the room specified in the Confirmation Email.</p>
            <p>The Service is subject to limited availability of Rooms present at the airport chosen by the Client prior to purchase. By making a reservation, the client indicates that they have read and understood the Room Use Regulations (the 'Regulations') published on the Website and therefore accepts these General Conditions, and the Regulations. To access the reserved Room specified in the Acceptance Email, the Client must enter the reservation code received by email.</p>
            <p>In the event that the Client contracts the service in person at the location through the touch screens, the length of stay is subject to the availability of rooms at the time of contracting, so the time will be charged when the Client checks out.</p>
            <p>In the event that there is no availability of rooms due to occupation or reservations at the time of contracting the service, the Client may register by leaving their contact information so that the system can notify them when there are rooms available. The client may accept or reject the notification of availability that the system sends.</p>
            <br></br>

            <h3>4. CONTRACT CELEBRATION</h3>
            <p>The parties acknowledge, understand and accept that the contracted hosting service constitutes an agreement of wills between the Client and Wait N Rest, which is governed by the terms and conditions of this document, in addition to observing what is established in Colombian legislation, particularly the Civil Code, Commercial Code, Law 1480 of 2011 regarding the Consumer Statute and other related norms. It is understood that the information published on the summary screen is an Offer from Wait N Rest that may be accepted or not by the Client and that is conditioned to the terms of this document.</p>
            <p>By contracting the service, the Client acknowledges having read and understood the terms and conditions of their contracting, while accepting its content. Prior to contracting the service, Wait N Rest will review the availability of rooms and desired schedules by the Client. Once Wait N Rest confirms the availability of the reserved room, it will send an email to the client confirming the reservation made. At this point, the service will have been contracted, and the Client will be obliged to make the payment of the confirmed reservation, immediately, under penalty of the reservation made by the Client, and confirmed by Wait N Rest, being canceled.</p>
            <br></br>

            <h3>5. RESERVATION PROCESS</h3>
            <p>The client can reserve the use of a Wait N' Rest room by registering on the touch screens located outside the venue or by using the reservation website www.waitnrest.com. The registration process requires the client to set a password for their room and remember the room number assigned by the Wait N Rest system to be able to enter the venue and later access the reserved room. The Client has the right to reserve rooms in accordance with the provisions of these Terms and Conditions. The use of rooms is individual and in observance of the Regulations and the Terms and conditions described herein. The application of sanctions in accordance with article 8 of these General Conditions, in case of non-compliance or irregular compliance by the Client with the obligations referred to in these General Conditions and the Regulations, and in particular in the case of negligence, fault, negligence and inexperience of the Client, the latter will be liable to Wait N Rest for damages and will have to pay the corresponding compensation.</p>
            <br></br>

            <h3>6. CONSIDERATION</h3>
            <p>The consideration due by the Client for the acquired Service will be indicated in dollars on the summary screen and will include all applicable taxes or duties. The client will decide which rate to choose at the time of making the reservation. The selection of the Service and the rate chosen by the Client.</p>
            <br></br>

            <h3>7. PAYMENT OF THE AMOUNT AND BILLING</h3>
            <p>The Client will make the payment of the corresponding rate through STRIPE. Wait N Rest may not, at any step of the purchase process, be aware of the information related to the Client's credit and/or debit card. Under no circumstances, Wait N Rest is responsible for any fraudulent or improper use of credit or debit cards by third parties, at the time of payment of the service acquired on the web or touch screens located at Wait N Rest's venue.</p>
            <br></br>

            <h3>8. USE OF THE WAIT N’ REST ROOM</h3>
            <p>The Customer understands and accepts that they must comply with the provisions of use described in the general conditions of use of this document and also the rules of use of the airport where the Wait N' Rest room is located, without exception. The customer agrees that the reservation is personal and non-transferable and that the use of the room is individual and the maximum stay time is eight (8) consecutive hours. It is prohibited to remove equipment from the room. In the event that the Customer fails to comply with the regulations, Wait N Rest may notify the relevant authorities. The customer who last used the room will be responsible for any damages and losses reported by Wait N' Rest cleaning staff. The corresponding amount for reported furniture and equipment damages will depend on and be debited from the responsible customer's registered payment method, without prejudice to the customer's right to demonstrate that the reported damage was due to causes that cannot be attributable to them.</p>
            <br></br>

            <h3>9. RESOLUTION</h3>
            <p>In cases where the Customer: a) is under 18 years of age; b) uses false credentials and/or documents when reserving the room; c) fails to comply with any of the obligations regarding room usage as established in the Regulations; constitute cases of serious breach, upon which Tivat Colombia S.A.S. has the right to terminate the contract in accordance with Colombian law due to the fact and fault of the Customer and without prejudice to compensation for additional damages, by means of a simple written notification.</p>
            <br></br>

            <h3>10.	USE OF ROOMS FOR PEOPLE WITH REDUCED MOBILITY</h3>
            <p>By legal provisions, Tivat Colombia S.A.S. has a room enabled for people with reduced mobility which, in the event that it is not reserved for a person with such condition, may be reserved by a Customer without such condition. Tivat Colombia S.A.S. warns that in the event that there is no availability of rooms due to occupancy, and waiting reservations are generated in the room for people with reduced mobility, said person will have priority over others who have reserved.</p>
            <br></br>

            <h3>11. ROOM ACCESS</h3>
            <p>The Client must use the password provided during the booking process to access the Wait N' Rest room. It is the client's responsibility to remember the password for their room and the room number they reserved. If the client forgets their room number, they must contact the Wait N' Rest help center via email at waitnrestcontact@gmail.com or request assistance from a Wait N' Rest employee on site using the help button on the exterior touchscreens. Under no circumstances will Wait N' Rest provide a refund for the time of service consumed. The client may leave and enter the room and the facility as many times as they wish using their access code. The stay time in the Wait N' Rest room will start from the completion of the registration process (after entering payment information) and will end with the completion of the check-out process carried out on the exterior touch screen of the room used. The Wait N' Rest room will be kept secure throughout the client's stay and will only be opened with their unique code and the access key of the cleaning staff.</p>
            <br></br>

            <h3>12. CHECK-OUT PROCESS</h3>
            <p>The Client must use the password provided during the booking process to access the Wait N' Rest room. It is the client's responsibility to remember the password for their room and the room number they reserved. If the client forgets their room number, they must contact the Wait N' Rest help center via email at waitnrestcontact@gmail.com or request assistance from a Wait N' Rest employee on site using the help button on the exterior touchscreens. Under no circumstances will Wait N' Rest provide a refund for the time of service consumed. The client may leave and enter the room and the facility as many times as they wish using their access code. The stay time in the Wait N' Rest room will start from the completion of the registration process (after entering payment information) and will end with the completion of the check-out process carried out on the exterior touch screen of the room used. The Wait N' Rest room will be kept secure throughout the client's stay and will only be opened with their unique code and the access key of the cleaning staff.</p>
            <br></br>

            <h3>13. REFUNDS</h3>
            <p>Only clients who, despite booking the room online, were unable to access it at the start of the reservation due to the room being in the cleaning process or those who, following the instructions set out in our terms and conditions and rules of use, request the cancellation of their online booking considering the minimum time established of 48 hours before the start of such reservation, will be entitled to refunds. The client acknowledges that the refund process will be handled by the payment processing platform STRIPE, whose policies can be found at https://stripe.com/docs/refunds. The client agrees and understands that TIVAT COLOMBIA SAS has no influence on the speed of the refund of funds and that the only document that can be provided to the client as evidence of the refund request is a copy of the refund request issued by the STRIPE platform.</p>
            <br></br>

            <h3>14. ALERTS AND COMMUNICATIONS</h3>
            <p>Wait N Rest reserves the right to change the room number reserved by the Client via the website if it deems it appropriate. Such change will be communicated by Wait N Rest to the Client by sending an email to the address provided in the reservation process or by the staff on site. It is the Client's responsibility to check their email before the date of their reservation to be aware of any changes. Wait N Rest will send alerts to the Client's provided email address to: notify them of the completion of the service purchased in case of a reservation made online, to notify them of the proximity to the maximum stay time allowed, which is eight (8) hours, and to send information on checkout/check-in or penalties charged to the Client for non-compliance with the Wait N Rest room use regulations.</p>
            <br></br>

            <h3>15. DAMAGES</h3>
            <p>It is the Client's responsibility to notify Wait N' Rest customer service via email at waitnrestcontact@gmail.com or by notifying a member of Wait N' Rest cleaning staff about any damage to the furniture or equipment in the room upon check-in. The Client may choose to be reimbursed or change rooms subject to availability. In case of a widespread internet connection loss at the airport or a failure of the Wait N' Rest operating system, the Client must notify a member of Wait N' Rest staff about the situation in order to request a manual check-out.</p>
            <br></br>

            <h3>16. LOST ITEMS</h3>
            <p>Wait N' Rest is not responsible in any case for the loss or damage of personal belongings left in the room or for the consequences that may result from the time it takes Wait N' Rest cleaning staff to recover the Client's belongings left inside the room. Wait N' Rest will make the best possible effort to deliver the Client's personal belongings as long as their loss is reported within the first 10 minutes after check-out. Wait N Rest will keep lost and found items for a maximum period of 30 calendar days. In case they are not claimed, these items will be donated to charity or disposed of.</p>
            <br></br>

            <h3>17. EXEMPTION OF RESPONSABILITY</h3>
            <p>Except in cases of fraud or gross negligence on the part of Wait N Rest, it is not responsible for any direct or indirect damages of any nature that the Client or third parties may suffer. Wait N Rest is not responsible for losses caused by theft and/or loss of items within or around the room or items left by the Client inside the room or its surroundings. The Manager will take care of such items left and found by the staff for a period of 30 calendar days. If the lost item is not claimed within the specified period, it will be donated to charity or disposed of.</p>
            <br></br>

            <h3>18. RATES</h3>
            <p>The Client declares to know the rates of the service before registering to use the Wait n' Rest room. These will be displayed during the room registration and reservation process on the touch screen located outside the Wait N' Rest venue and on the booking website portal. In addition, the Client declares to have visited www.waitnrest.com/bogota before accessing our service.</p>
            <br></br>

            <h3>19. PAYMENTS</h3>
            <p>The client declares to be aware of the payment method of the Wait N' Rest service before making a reservation and registering to use the Wait N' Rest room. This method consists of a charge for the use of the room that is billed per hour of consumption. The hourly rate applies equally to the hour or fraction of minutes of use of the room. This method only applies to reservations made by the Client on the touch screens located outside the Wait N Rest premises. During the reservation process, the client agrees that the payment gateway STRIPE will make a pre-authorization to the Client's card of $83.92. Upon completing the room check-out process, our system only captures the amount corresponding to the Client's stay time. The release of the remaining 'frozen' balance will depend on the authorization retention policies of the Client's card issuing bank. The Client declares to accept and understand that TIVAT COLOMBIA SAS has no responsibility under any circumstances for the time in which the frozen funds will be reflected in the payment method designated by the Client, and that said procedure will depend solely and exclusively on the Client's card issuing bank. The client accepts and understands that the pre-authorization of $83.92 is not equivalent to a charge, and therefore; it is not money that TIVAT COLOMBIA SAS receives at that time; on the contrary, it is money 'frozen' by the STRIPE payment gateway so that, after check-out, the payment gateway can capture the corresponding amount and send it to TIVAT COLOMBIA SAS.</p>
            <br></br>

            <h3>20. APPLICATION</h3>
            <p>Wait N' Rest adopts the necessary and reasonable security measures for the operation of the Application used by the touch screens located outside the Wait N' Rest room. However, these measures do not necessarily ensure the absence of viruses or other elements in the Application, introduced by third parties, beyond the control of Wait N' Rest, that may cause alterations to the physical or logical systems of the Registered User or to the electronic documents and files stored in their systems. Consequently, Wait N' Rest will not be responsible for damages that may arise from the presence of viruses or other elements beyond the control of Wait N' Rest, that may cause alterations to the physical or logical systems, electronic documents, or files of the Registered User.</p>
            <br></br>

            <h3>21. PROTECTION</h3>
            <p> Wait N' Rest adopts various measures to protect the Application and its contents against computer attacks by third parties. However, Wait N' Rest does not guarantee that unauthorized third parties will not access the conditions, characteristics, and circumstances in which the Client accesses the Application. Consequently, Wait N' Rest will not be responsible for damages that may arise from such unauthorized access.</p>
            <br></br>

            <h3>22. PAYMENT PROCESSING</h3>
            <p>Payments made by the Client will be made through the STRIPE payment platform. The Client declares to have read STRIPE's privacy and data processing policies, which can be consulted at https://stripe.com/privacy. In the event of reversals or chargebacks, the Client declares to be aware that Wait N' Rest has no responsibility for the speed of the money refund and that it is governed by STRIPE's chargeback and reversal policy, which can be consulted at https://stripe.com/docs/refunds.</p>
            <br></br>

            <h3>23. PENALTIES</h3>
            <p>Wait N Rest reserves the right to apply to the Client the sanctions indicated in this article when the situations provided for and regulated by the General Conditions and by Regulation occur, without prejudice, in any case, to compensation for greater damages. In particular, Wait N Rest informs the Client through an additional debit entry on the receipt of the amount of the fine, which will be charged simultaneously to the credit or debit card used by the Client to pay for the Service. Types of applicable sanctions. In any case, the Client understands that any misuse of the room will be considered to contravene the contents of these Terms and Conditions, as well as the contents of the Use Regulations, which are an integral part of this document.</p>
            <br></br>

            <h3>24 GENERAL PROVISIONS</h3>
            <p>Acceptance of these General Conditions is necessary to make the reservation. The General Conditions may be subject to changes and the most updated version will always be available on the Site. Wait N Rest reserves the right to modify these General Conditions at any time, without prior notice, understanding that the purchase by the Client, validated by Wait N Rest, in accordance with the provisions of these General Conditions, shall be governed by the General Conditions contained in the Acceptance email. By acquiring the Service, the Client declares to be of legal age. These general provisions are governed by the Consumer Statute contained in Law 1480 of 2011 and by the other relevant provisions offered by the Colombian legal system. For any needs or complaints related to the purchase of the Services on the Site, the Client can contact personnel on site or through the email waitnrestcontact@gmail.com</p>
            <br></br>

            <h3>25 MEMBERSHIPS AND DISCOUNTS </h3>
            <p>Clients who have memberships or discounts applicable to the Wait N Rest service will be solely responsible for indicating such a special condition to the staff at the Wait N´Rest location prior to reserving and paying for a room. Under no circumstances will TIVAT COLOMBIA SAS make refunds, returns, or cancellations of membership or discount visits after the room reservation process has ended. The Client accepts and understands that the benefit of the Priority Pass and/or Lounge Key membership will only be valid during the time slot from 8:00 AM to 9:00 PM, regardless of what is stated in any communication medium that the Client has with Priority Pass and/or Lounge Key.</p>
            <p>Clients belonging to the Priority Pass and/or Lounge Key benefits program may enter the Wait N´Rest location with a maximum of (1) companion or guest per registration. It is the responsibility of the membership holder to ensure that their benefits program includes free invitation of companions to establishments with an agreement with Priority Pass and Lounge Key. Under no circumstances will TIVAT COLOMBIA SAS be responsible for any charges that banking entities may charge the Priority Pass and/or Lounge Key membership holder later on. The Client agrees that only one discount per room reservation or registration may apply.</p>
            <br></br>

            <h3>26. APPLICABLE LAW AND DISPUTES</h3>
            <p>The terms and conditions set forth in this document are governed by Colombian law. Any controversy or difference related to this document, as well as its execution that is not resolved directly between the parties, will be resolved by the conciliation rooms of the Mercantile Arbitration and Conciliation Center of the Bogotá Chamber of Commerce. The conciliator will be subject to the provisions of the Civil and Commercial Procedure Codes in accordance with the following rules: a. The conciliation room will be composed of a conciliator appointed by the Bogotá Chamber of Commerce. b. The Conciliator will be subject to the Rules provided for this purpose by the Mercantile Arbitration and Conciliation Center of the Bogotá Chamber of Commerce.</p>
            <br></br>

            <h3>27. FINAL DECLARATION</h3>
            <p>THE USER DECLARES TO HAVE READ AND UNDERSTOOD ALL THE CONDITIONS ESTABLISHED IN THE PRIVACY POLICIES AND THESE GENERAL TERMS AND CONDITIONS, AND DECLARES THEIR CONFORMITY AND ACCEPTANCE AT THE TIME OF RESERVING A ROOM. ANY PERSON WHO DOES NOT ACCEPT OR DISAGREE WITH THESE GENERAL TERMS AND CONDITIONS, WHICH HAVE A MANDATORY AND BINDING CHARACTER, MUST REFRAIN FROM USING THE SERVICE. Wait and Rest is a service of hourly stays, which as established by law: 'Ley 300 of 1996 Article 2.2.4.4.12.4. Hotel Registration Card. lodging establishments, excluding establishments that provide lodging services by the hour.'For its part, article 12 of Law 1101 of 2006 modified by Decree 229 of 2017, states that tourism service providers listed below must register with the National Tourism Registry: 'Hotels, vacation centers, campsites, tourist homes and other types of non-permanent lodging, excluding establishments that provide lodging services by the hour.'</p>
            <br></br>

            <h3>These are the rules of use for Wait N' Rest accommodations:</h3>
            <p>1.	The room is for individual use only. If two or more people enter during your stay, it will result in a penalty of $80 USD charged to the registered card on the reservation. Additionally, all parties will be required to vacate the Wait N' Rest facilities without refund or claim.</p>
            <p>2.	Smoking of any kind is strictly prohibited inside the facilities and will result in a penalty of $100 USD charged to the registered card on the reservation. (Tobacco, vapers, psychoactive substances)</p>
            <p>3.	Exceeding the maximum stay time or the scheduled check-out time will result in an hourly fee of $17.2 USD for each additional hour or fraction thereof. Guests are allowed a five-minute grace period before the hourly fee applies.</p>
            <p>4.	Destroying or disabling smoke detectors will result in a $100 USD penalty charged to the registered card on the reservation.</p>
            <p>5.	Forgotten items will be kept at Tivat Colombia SAS offices for 30 calendar days. After this time, if there is no claim for the items, they will be donated to charity. Lost items will only be returned to the person who made the reservation in which the items were lost.</p>
            <p>6.	Wait N' Rest allows food purchased at authorized establishments in the airport's R Zone</p>
            <p>7.	Destroying or damaging the touch screen located inside the room will result in a penalty of $100 USD charged to the registered card on the reservation.</p>
            <p>8.	Taking any items from inside the room is strictly prohibited. Failing to comply with this rule will result in a penalty equivalent to the cost TIVAT COLOMBIA SAS considers necessary to replace the missing item, considering the damage that this attitude may cause to the service provided. Personnel will conduct an inventory before the room is handed over to have control over any missing or damaged items.</p>
            <p>9.	The minimum age to use the room is 18 years.</p>
            <p>10. Once the guest has paid the room rate, no refunds will be issued under any circumstances.</p>
            <p>11. Accommodation is non-transferable, and no other people are allowed in place of the guests.</p>
            <p>12. Guests must be respectful of others and limit the volume of any electronic or electrical devices that generate noise or loud sounds to avoid exceeding the decibel level allowed by the capsules.</p>
            <p>13. In the event of theft, robbery, or intentional damage, the police will be notified immediately.</p>
            <p>14. Guests must report emotional support animals or pets to the staff. If there is any damage to the furniture, the penalty cost will be up to $100 USD or the guest may be required to pay for the full repair costs determined by TIVAT COLOMBIA SAS.</p>
            <p>15. Damage to furniture or the property itself is the sole responsibility of the guest, and they will be required to pay for any repairs determined by TIVAT COLOMBIA SAS.</p>
            <p>16. For complaints or suggestions, guests should contact the front desk and ask for the Site Manager or the shift supervisor.</p>
            <p>17. Wait N' Rest is not responsible for the loss of jewelry, money, documents, or other valuables of any kind kept in the rooms due to the unique access password assigned by the guest upon check-in.</p>
            <p>18. All guests staying in Wait N' Rest accommodations are subject to these regulations, which constitute an adherence contract. Failure to comply may result in Wait N' Rest reserving the right to refuse admission or continuation of stay.</p>
            <p>19. Guests who display aggressive, threatening behavior, or are intoxicated or disrespectful to staff or other guests must leave the rooms immediately and will be reported to the competent authority.</p>
            <p>20. The minimum time to request a refund for a web reservation is 48 hours before the start of the reservation. In order for this request to be valid, the customer must send an email to waitnrestcontact@gmail.com requesting the refund.</p>
            <p>21. Leaving trash, leftovers, dirtying, wetting surfaces or mattress, or any other activity that implies differentiated cleaning of the room to make it available for a subsequent customer will result in a fine of up to 100 USD charged to the customer's registered card for the use of said room. The site manager may decide to charge $100 or a lower amount depending on the severity of the state in which the customer left the room.</p>
            <br></br>










            </div>
        </DialogLayout>
    )
}
