import React, { useEffect, useState } from 'react' 
import { Grid, TextField, Container, MenuItem, FormControl, InputLabel, Select, Button, CircularProgress } from '@material-ui/core'
import * as Storage from '../../../utils/storage';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toastError } from '../../../utils/toast';
import { getCountries, register } from '../../../data';
import TextFieldPassword from '../../base/TextFieldPassword/TextFieldPassword';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD } from '../../../contants/globals';

export default function RegisterForm({ setShowCabinForm, setOption }) {
    const { t } = useTranslation();
    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        //setShowCabinForm(true);
        if (Storage.getItem(Storage.TOKEN_USER)) {
            setIsLogged(true);
            setShowCabinForm(true);
        }
        getCountries()
            .then(data => {
                setCountries(data);
            })
            .catch(console.log)
    }, [])

    const formik = useFormik({
        initialValues: {
            cust_firstname: '',
            cust_lastname: '',
            cust_email: '',
            cust_country_id: '',
            user_password: '',
            user_repeat_password: '',
        },
        validationSchema: Yup.object({
            cust_firstname: Yup
                .string()
                .required(t('Validation Firstnames')),
            cust_lastname: Yup
                .string()
                .required(t('Validation Lastnames')),
            cust_country_id: Yup
                .string()
                .required(t('Validation Country')),
            cust_email: Yup
                .string()
                .required(t('Validation Email'))
                .email(t('Validation Invalid Email')),
            user_password: Yup.string()
                .required(t('Validation Password'))
                .oneOf([Yup.ref("user_repeat_password")], t('Validation Password Not Equal'))
                .min(MIN_LENGTH_PASSWORD, t('Validation Password Min'))
                .max(MAX_LENGTH_PASSWORD, t('Validation Password Max')),
            user_repeat_password: Yup.string()
                .required(t('Validation Password'))
                .oneOf([Yup.ref("user_password")], t('Validation Password Not Equal'))
        }),
        onSubmit: async (formData) => {
            console.log(formData);
            setLoading(true);
            try {
                const response = await register(formData);
                console.log(response);
                Storage.saveItem(Storage.TOKEN_USER, response.token);
                Storage.saveItem(Storage.EMAIL_AUTH, response.cust_email);
                Storage.saveItem(Storage.FULLNAMES_AUTH, response.cust_fullnames);
                Storage.saveItem(Storage.COUNTRY_AUTH, response.country.coun_name);
                formik.resetForm();
                setShowCabinForm(true);
                setIsLogged(true);
            } catch (error) {
                toastError(error?.response?.data?.message || t('Validation Not Registered'))
            } finally {
                setLoading(false);
            }
        }

    })

    const logout = () => {
        Storage.clearAll();
        setIsLogged(false);
        setShowCabinForm(false);
        setOption('Login');
    }

    const goToLogin = () => setOption('Login');

    return (
        <>
            {!isLogged && <div className='register-form'>
                <Container>
                    <h1>{t('Register')}</h1>
                    <br></br>
                    <p>{t('Register Have an account')} <a src='#' className='pointer' onClick={goToLogin}>{t('Login')}</a></p>
                    <br></br>
                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <Grid className='form-grid' container spacing={3} justify='center'>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="cust_firstname"
                                    label={t('Form Firstname')}
                                    variant="outlined"
                                    size={'small'}
                                    onChange={formik.handleChange}
                                    value={formik.values.cust_firstname}
                                    error={formik.touched.cust_firstname && formik.errors.cust_firstname}
                                    helperText={formik.touched.cust_firstname ? formik.errors.cust_firstname : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="cust_lastname"
                                    label={t('Form Lastname')}
                                    variant="outlined"
                                    size={'small'}
                                    onChange={formik.handleChange}
                                    value={formik.values.cust_lastname}
                                    error={formik.touched.cust_lastname && formik.errors.cust_lastname}
                                    helperText={formik.touched.cust_lastname ? formik.errors.cust_lastname : ''}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl size={'small'} variant="outlined" fullWidth>
                                    <InputLabel id="label_cust_country_id">{t('Form Country')}</InputLabel>
                                    <Select
                                        labelId="label_cust_country_id"
                                        id="cust_country_id"
                                        label={t('Form Country')}
                                        onChange={(event, child) => formik.setFieldValue('cust_country_id', event.target.value)}
                                        value={formik.values.cust_country_id}
                                        error={formik.touched.cust_country_id && formik.errors.cust_country_id}
                                        helperText={formik.touched.cust_country_id ? formik.errors.cust_country_id : ''}
                                    >
                                        <MenuItem value="">
                                            <em>{t('None')}</em>
                                        </MenuItem>
                                        {
                                            countries.map((item, index) => (
                                                <MenuItem key={index} value={item.coun_id}>{`${item.coun_name}`}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="cust_email"
                                    label={t('Form Email')}
                                    variant="outlined"
                                    size={'small'}
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.cust_email}
                                    error={formik.touched.cust_email && formik.errors.cust_email}
                                    helperText={formik.touched.cust_email ? formik.errors.cust_email : ''}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* <TextField
                                    id="user_password"
                                    label="Password"
                                    variant="outlined"
                                    size={'small'}
                                    type="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.user_password}
                                    error={formik.touched.user_password && formik.errors.user_password}
                                    helperText={formik.touched.user_password ? formik.errors.user_password : ''}
                                    fullWidth
                                /> */}
                                <TextFieldPassword
                                    id="user_password"
                                    label={t('Form Password')}
                                    variant="outlined"
                                    type={'password'}
                                    labelWidth={80}
                                    size={'small'}
                                    onChange={formik.handleChange}
                                    value={formik.values.user_password}
                                    error={formik.touched.user_password && formik.errors.user_password}
                                    helperText={formik.touched.user_password ? formik.errors.user_password : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextFieldPassword
                                    id="user_repeat_password"
                                    label={t('Form Repeat Password')}
                                    variant="outlined"
                                    type={'password'}
                                    labelWidth={140}
                                    size={'small'}
                                    onChange={formik.handleChange}
                                    value={formik.values.user_repeat_password}
                                    error={formik.touched.user_repeat_password && formik.errors.user_repeat_password}
                                    helperText={formik.touched.user_repeat_password ? formik.errors.user_repeat_password : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item xs={12} justify='center'>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    type='submit'
                                    disabled={loading}
                                >
                                    {!loading && t('Register')}
                                    {loading && <CircularProgress color='inherit' size={20} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </div>}
            {isLogged && <div className='login-info'>
                <Container>
                    <h1>{t('Login Logged')}</h1>
                    <br></br>
                    <p><strong>{Storage.getItem(Storage.FULLNAMES_AUTH)}</strong></p>
                    <p>{Storage.getItem(Storage.EMAIL_AUTH)}</p>
                    <p>{Storage.getItem(Storage.COUNTRY_AUTH)}</p>
                    <br></br>
                    <p>{t('Login Not you?')} <a src='#' onClick={logout}>{t('Logout')}</a></p>
                </Container>
            </div>}
        </>
    )
}
