import React from 'react' 
import { withStyles } from '@material-ui/core/styles'; 
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography'; 

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        color: theme.palette.primary.main,
        background: theme.palette.secondary.main,
    },
    dialog: {
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    // const { t } = useTranslation();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiDialogContent);

export default function AccountModal({ open, handleClose,title,children,maxWidth='xs'}) {
    return (
        <div className={'account-dialog'}>
            <Dialog 
            onClose={handleClose} 
            aria-labelledby="customized-dialog-title" 
            open={open} 
            maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose} classes='title'>
                    <span className='title'>{title}</span>
                </DialogTitle>
                <DialogContent 
                dividers
                className={'account-dialog-content'}
                >
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    )
}
