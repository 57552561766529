import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import DialogLayout from '../../../layouts/DialogLayout'
import './PrivacyPolicyModal.scss'

export default function PrivacyPolicyModalEn({ open, handleClose }) {
    const { t } = useTranslation();
    return (
        <DialogLayout
            title={t('Privacy policy')}
            open={open}
            handleClose={handleClose}
            maxWidth='lg'
        >
            <div className='content-term-and-conditions'>
            <h3>PERSONAL DATA TREATMENT POLICY</h3>
            <p>In accordance with the provisions of article 15 of the Political Constitution, Law 1266 of 2008, Law 1581 of 2012, regulatory decrees 1727 of 2009 and 2952 of 2010, partial regulatory decree No 1377 of 2013, Sentences of the Constitutional Court C – 1011 of 2008, and C – 748 of 2011.</p>
            <p>This Policy shall apply to all Personal Data that TIVAT COLOMBIA S.A.S, (hereinafter WNR) identified with the NIT 901504472-5 collects, retains, orders, stores, modifies, relates, uses, circulates, evaluates, transfers, transmits, deletes, and, in general, processes in the course of the activities (the 'Processing') carried out by TIVAT COLOMBIA S.A. S in the course of its commercial activities, in the relationship with its employees, users, suppliers and customers both in Colombia and internationally, its employees and/or by those third parties with whom WNR agrees to carry out any activity related to, or related to, the Processing of Personal Data, for which WNR is responsible. This Policy shall also apply to Processing that WNR performs through other websites operated by WNR or its affiliates.</p>
            <p>In addition, this Policy shall apply to third parties with whom WNR may enter into contracts for the transfer of Personal Data, so that such third parties are aware of the obligations that will apply to them when processing Personal Data on behalf of WNR.</p>
            <br></br>

            <h3>GENERAL PRINCIPLES AND POSTULATES GENERAL PRINCIPLES AND POSTULATE</h3>
            <p>WNR guarantees the protection of rights such as Habeas Data, privacy, intimacy, good name, image and autonomy, for this purpose all actions will be governed by principles of good faith, legality, computer self-determination, freedom and transparency.</p>
            <p>People who, in the exercise of any activity, including commercial and labor, whether permanent or occasional, may provide any type of information or personal data to WNR and in which it acts as data controller or data controller, may know it, update it and rectify it.</p>
            <br></br>

            <h3>DEFINITION</h3>
            <p>In accordance with the current legislation on the matter, the following definitions are established, which will be applied and implemented accepting the interpretation criteria that guarantee a systematic and integral application, and in accordance with technological advances, technological neutrality; and the other principles and postulates that govern the fundamental rights that surround, orbit and surround the right of habeas data and protection of personal data.</p>
            <p>Authorization: Prior, express and informed consent of the owner to carry out the processing of personal data.</p>
            <p>Database: Organized set of personal data that is subject to Treatment.</p>
            <p>Personal data: Any information linked or that may be associated to one or several determined or determinable natural persons.</p>
            <p>Public data: Data qualified as such according to the provisions of the law or the Political Constitution and all those that are not semi-private or private, in accordance with this law. Public data are, among others, the data contained in public documents, duly executed court rulings that are not subject to confidentiality and those relating to the civil status of persons.</p>
            <p>Private data: Data which, due to its intimate or reserved nature, is only relevant to the owner.</p>
            <p>Semi-private data: Semi-private data is data that is not of an intimate, reserved or public nature and whose knowledge or disclosure may be of interest not only to its owner but also to a certain sector or group of persons or to society in general, such as financial and credit data of commercial or service activity.</p>
            <p>Data processor: Natural or legal person, public or private, who by himself or in association with others, carries out the processing of personal data on behalf of the data controller. </p>
            <p>Data subject: Natural person whose personal data is the object of processing. </p>
            <p>Processing: Any operation or set of operations on personal data, such as collection, storage, use, circulation or deletion. </p>
            <p>Transfer: It takes place when the Controller and/or Processor of personal data, located in Colombia, sends the information or personal data to a recipient, which in turn is the Controller of the Processing and is located inside or outside the country.</p>
            <p>Transmission: Processing of personal data that involves the communication of the same within or outside the territory of the Republic of Colombia, when the purpose is the performance of a Processing by the Processor on behalf of the Controller</p>
            <br></br>

            <h3>SPECIFIC PRINCIPLES</h3>
            <p>WNR will apply specific principles set forth below, which constitute the rules to be followed in the collection, handling, use, processing, storage and exchange of personal data: </p>
            <p>Principle of legality: in the use, capture, collection and processing of personal data, the current and applicable provisions governing the processing of personal data and other related fundamental rights will be applied. </p>
            <p>Principle of freedom: the use, capture, collection and processing of personal data may only be carried out with the prior, express and informed consent of the Data Subject. Personal data may not be obtained or disclosed without prior authorization, or in the absence of a legal, statutory or judicial mandate that relieves consent. </p>
            <p>Principle of purpose: the use, capture, collection and processing of personal data to which WNR has access and are collected and gathered by WNR, will be subordinated and will serve a legitimate purpose, which must be informed to the respective holder of the personal data.</p>
            <p>Principle of truthfulness or quality: the information subject to use, capture, collection and processing of personal data must be truthful, complete, accurate, current, verifiable and understandable. The processing of partial, incomplete, fractioned or misleading data is prohibited.</p>
            <p>Principle of transparency: in the use, capture, collection and processing of personal data, the right of the Data Subject to obtain from WNR, at any time and without restrictions, information about the existence of any type of information or personal data that is of interest or ownership must be guaranteed.</p>
            <p>Principle of restricted access and circulation: Personal data, except for public information, may not be available on the Internet or other means of dissemination or mass communication, unless access is technically controllable to provide restricted knowledge only to the Data Subject or authorized third parties. For these purposes, WNR's obligation will be medium.</p>
            <p>Security Principle: Personal data and information used, captured, collected and subject to treatment by WNR, will be protected to the extent that technical resources and minimum standards allow, through the adoption of technological protection measures, protocols, and all kinds of administrative measures that are necessary to provide security to electronic records and repositories to prevent tampering, modification, loss, consultation, and in general against any unauthorized use or access. </p>
            <p>Principle of confidentiality: Each and every person who administers, manages, updates or has access to information of any kind that is in databases or data banks, undertakes to keep and maintain strictly confidential and not disclose to third parties, all personal, commercial, accounting, technical, commercial or any other type of information provided in the performance and exercise of their duties. All persons who are currently working or will be hired in the future for such purpose, in the administration and management of databases, must sign an additional document to their employment or service contract in order to ensure such commitment. This obligation persists and is maintained even after the end of their relationship with any of the tasks included in the processing. </p>
            <br></br>

            <h3>SENSITIVE DATA</h3>
            <p>Sensitive data are those that affect the privacy of the holder or whose improper use may generate discrimination, such as those that reveal racial or ethnic origin, political orientation, religious or philosophical convictions, membership in trade unions, social organizations, human rights or that promote the interests of any political party or that guarantee the rights and guarantees of opposition political parties, as well as data related to health, sexual life and biometric data, among others, of human rights or that promote the interests of any political party or that guarantee the rights and guarantees of opposition political parties as well as data related to health, sexual life and biometric data, among others, still or moving image capture, fingerprints, photographs, iris, voice, facial or palm print recognition, etc. </p>
            <br></br>

            <h3>PROCESSING OF SENSITIVE DATA</h3>
            <p>Use and processing of data classified as sensitive may be made when:</p>
            <p>a.	The Data Subject has given his explicit authorization to such processing, except in cases where by law the granting of such authorization is not required.</p>
            <p>b.	The processing is necessary to safeguard the vital interest of the data subject and he/she is physically or legally incapacitated. In these events, the legal representatives must grant their authorization. </p>
            <p>c.	The Processing is carried out in the course of legitimate activities and with due guarantees by a foundation, NGO, association or any other non-profit organization, whose purpose is political, philosophical, religious or trade union, provided that they refer exclusively to its members or to persons who maintain regular contacts by reason of their purpose. In these events, the data may not be provided to third parties without the holder's authorization. </p>
            <p>d.	The processing refers to data that are necessary for the recognition, exercise or defense of a right in a judicial process. </p>
            <p>e.	The processing has a historical, statistical or scientific purpose. In this event, the measures leading to the suppression of the identity of the Holders must be adopted.</p>
            <br></br>

            <h3>AUTHORIZATION OF THE HOLDER</h3>
            <p>Without prejudice to the exceptions provided by law, the processing requires the prior, express and informed authorization of the Holder, which must be obtained by any means that may be subject to consultation and subsequent verification. </p>
            <br></br>

            <h3>CASES IN WHICH AUTHORIZATION IS NOT REQUIRED</h3>
            <p>The authorization of the Data Subject shall not be required when it concerns: </p>
            <p>a.	Information required by a public or administrative entity in the exercise of its legal functions or by court order.</p>
            <p>b.	Data of a public nature.</p>
            <p>c.	Cases of medical or health emergency.</p>
            <p>d.	Processing of information authorized by law for historical, statistical or scientific purposes. </p>
            <p>e.	Data related to the Civil Registry of Persons. </p>
            <br></br>

            <h3>CHILDREN'S AND ADOLESCENTS' RIGHTS</h3>
            <p>The processing shall ensure respect for the prevailing rights of minors. The processing of personal data of minors is prohibited, except for data of a public nature. </p>
            <br></br>

            <h3>DUTIES OF WNR AS CONTROLLER OF THE PROCESSING OF PERSONAL DATA</h3>
            <p>When WNR acts as the Controller of the Processing of personal data, it shall comply with the following duties: </p>
            <p>a.	Guarantee the Data Subject, at all times, the full and effective exercise of the right of habeas data. </p>
            <p>b.	To request and keep a copy of the respective authorization granted by the Data Subject. </p>
            <p>c.	Duly inform the holder about the purpose of the collection and the rights he/she is entitled to by virtue of the authorization granted. </p>
            <p>d.	Keep the information under the security conditions necessary to prevent its adulteration, loss, consultation, use or unauthorized or fraudulent access.</p>
            <p>e.	Guarantee that the information provided to the data processor is truthful, complete, accurate, updated, verifiable and understandable.</p>
            <p>f.	Update the information, communicating in a timely manner to the data processor, all developments with respect to the data previously provided and take other necessary measures to ensure that the information provided to it is kept up to date.</p>
            <p>g.	Rectify the information when it is incorrect and communicate the pertinent to the data processor.</p>
            <p>h.	To provide the data processor, as the case may be, only data whose processing is previously authorized.</p>
            <p>i.	Demand from the Data Processor, at all times, to respect the security and privacy conditions of the Data Subject's information.</p>
            <p>j.	To process the queries and claims formulated. </p>
            <p>k.	Inform the Data Controller when certain information is under discussion by the Data Subject, once the claim has been filed and the respective process has not been completed.</p>
            <p>l.	Inform at the request of the Data Subject about the use given to his/her data.</p>
            <p>m.	Inform the data protection authority when there are violations to the security codes and there are risks in the administration of the information of the Data Controllers</p>
            <br></br>

            <h3>AUTHORIZATIONS AND CONSENT</h3>
            <p>The collection, storage, use, circulation or deletion of personal data by WNR requires the free, prior, express and informed consent of the data owner. </p>
            <br></br>

            <h3>MEANS AND MANIFESTATIONS TO GRANT THE AUTHORIZATION</h3>
            <p>The authorization may be recorded in a physical or electronic document, data message, Internet, Web Sites, in any other format that allows to guarantee its later consultation, or, by means of a suitable technical or technological mechanism, that allows to express or obtain the consent via click or double click, by means of which it can be concluded in an unequivocal way, that if the owner had not acted, the data would never have been captured and stored in the database. The authorization will be generated by WNR and will be made available to the holder in advance and prior to the processing of personal data.  </p>
            <br></br>

            <h3>PROOF OF AUTHORIZATION</h3>
            <p>WNR will use the mechanisms it currently has, and will implement and adopt the necessary actions to maintain records or suitable technical or technological mechanisms to prove when and how it obtained authorization from the holders of personal data for the processing of such data. In order to comply with the above, physical files or electronic repositories may be established directly or through third parties hired for such purpose. </p>
            <p>The Privacy Notice is the physical, electronic or any other known or to be known format, which is made available to the Holder for the processing of personal data. Through this document, the Data Subject is informed about the existence of the information processing policies that will be applicable to him/her, the way to access them and the characteristics of the processing that is intended to be given to the personal data.</p>
            <br></br>

            <h3>PREROGATIVES AND OTHER RIGHTS OF THE HOLDERS OF THE INFORMATION</h3>
            <p>In attention and in accordance with the provisions of the regulations in force and applicable to the protection of personal data, the holder of the personal data has the following rights:</p>
            <p>a.	Access, know, rectify and update their personal data against WNR, in its capacity as data controller. </p>
            <p>b.	By any valid means, request proof of the authorization granted to WNR, in its capacity as Data Controller. </p>
            <p>c.	To receive information from WNR, upon request, regarding the use it has made of your personal data.</p>
            <p>d.	To go before the legally constituted authorities, especially before the Superintendence of Industry and Commerce, and file complaints for violations of the provisions of current regulations in the applicable rules, after consultation or request to the Data Controller.</p>
            <p>e.	Modify and revoke the authorization and/or request the deletion of the data when the Processing does not respect the principles, rights and constitutional and legal guarantees in force.</p>
            <p>f.	To have knowledge of and access free of charge to their personal data that have been subject to Processing.</p>
            <br></br>

            <h3>DUTIES OF WNR IN RELATION TO THE PROCESSING OF PERSONAL DATA</h3>
            <p>WNR will keep in mind, at all times, that personal data are the property of the persons to whom they refer and that only they can decide about them. In this sense, it will use them only for those purposes for which it is duly empowered, and respecting in any case the current regulations on personal data protection.</p>
            <br></br>

            <h3>GUARANTEES OF THE RIGHT OF ACCESS</h3>
            <p>WNR will guarantee the right of access when, after accrediting the identity of the Data Subject, legitimacy, or personality of his representative, making available to him, at no cost or expense, in a detailed and detailed manner, the respective personal data through any type of media, including electronic media that allow direct access to them by the Data Subject. Such access must be offered without any limit and must allow the holder the possibility of knowing and updating them online.</p>
            <p>The customer service channels for the exercise of this right will be:</p>
            <p>- In writing via physical mail: _________ Bogotá - Colombia</p>
            <p>- In writing via e-mail: _________</p>
            <p>- Cellular: (+57) ________</p>
            <br></br>

            <h3>CONSULTATIONS</h3>
            <p>The Data Subjects, or their successors in title, may consult the personal information of the Data Subject contained in any database. Consequently, WNR will guarantee the right of consultation, providing the data subjects with all the information contained in the individual record or that is linked to the identification of the Data Subject. </p>
            <p>With respect to the attention of requests for consultation of personal data, WNR guarantees:</p>
            <p>a.	Enable electronic means of communication or others it deems relevant.</p>
            <p>b.	Establish forms, systems and other simplified methods, which must be informed in the privacy notice.</p>
            <p>c.	Use the customer service or complaint services it has in operation.</p>
            <p>d.	In any case, regardless of the mechanism implemented for the attention of consultation requests, they will be attended within a maximum term of ten (10) working days from the date of their receipt. When it is not possible to attend the consultation within such term, the interested party shall be informed before the expiration of the ten (10) days, stating the reasons for the delay and indicating the date on which the consultation will be attended, which in no case may exceed five (5) business days following the expiration of the first term.</p>
            <br></br>

            <h3>CLAIMS</h3>
            <p>The Data Subject or its assignees who consider that the information contained in a database should be corrected, updated or deleted, or when they notice the alleged breach of any of the duties contained in the Law, may file a complaint with the Data Controller, channeling it and sending it through the designated unit whose contact details are specified below in paragraph 22 of this document and that will exercise the function of protection of personal data within WNR.  </p>
            <p>The claim may be filed by the Holder, taking into account the information indicated in Article 15 of Law 1581 of 2012 and Decree 1377 of 2013, and other regulations that modify or add to them.</p>
            <br></br>

            <h3>IMPLEMENTATION OF PROCEDURES TO GUARANTEE THE RIGHT TO FILE CLAIMS.</h3>
            <p>At any time and free of charge, the holder or his representative may request WNR staff to rectify, update or delete his personal data, upon proof of identity. The rights of rectification, updating or deletion may only be exercised by:</p>
            <p>a.	The owner or his or her assignees, upon proof of identity, or through electronic instruments that allow him or her to identify himself or herself.</p>
            <p>b.	His/her representative, with prior accreditation of the representation.</p>
            <p>c.	When the request is made by a person other than the owner, the legal capacity or mandate to act must be duly accredited; and in case such capacity is not accredited, the request shall be deemed not to have been filed. The request for rectification, updating or deletion must be submitted through the means enabled by WNR indicated in the privacy notice and contain, at least, the following information:    </p>
            <p>i.	The name and address of the holder or any other means to receive the response.</p>
            <p>ii.	The documents proving the identity or the personality of its representative. </p>
            <p>iii.	The clear and precise description of the personal data with respect to which the holder seeks to exercise any of the rights.</p>
            <p>iv.	If applicable, other elements or documents that facilitate the location of the personal data.</p>
            <br></br>

            <h3>RECTIFICATION AND UPDATING OF DATA</h3>
            <p>WNR has the obligation to rectify and update, at the request of the Data Subject, the information about him/her that proves to be incomplete or inaccurate, in accordance with the procedure and terms set forth above. In this regard, the following shall be taken into account: In requests for rectification and updating of personal data, the Data Subject must indicate the corrections to be made and provide the documentation supporting his request.</p>
            <p>WNR is free to enable mechanisms that facilitate the exercise of this right, as long as they benefit the holder. Consequently, electronic or other means may be enabled as it deems appropriate. </p>
            <p>WNR may establish forms, systems and other simplified methods, which must be informed in the privacy notice and will be made available to the interested parties on the website.</p>
            <br></br>

            <h3>REMOVAL OF DATA</h3>
            <p>a.	He/she considers that they are not being treated in accordance with the principles, duties and obligations provided for in the regulations in force. </p>
            <p>b.	They are no longer necessary or relevant for the purpose for which they were collected.</p>
            <p>c.	The period necessary for the fulfillment of the purposes for which they were collected has been exceeded This deletion implies the total or partial elimination of personal information as requested by the holder in the records, files, databases or treatments carried out by WNR.</p>
            <p>d.	It is important to bear in mind that the right of cancellation is not absolute and the data controller may deny the exercise of this right when:</p>
            <p>i.	The Data Subject has a legal or contractual duty to remain in the database.</p>
            <p>ii.	The deletion of data hinders judicial or administrative proceedings related to tax obligations, the investigation and prosecution of crimes or the updating of administrative sanctions.</p>
            <p>iii.	The data is necessary to protect the legally protected interests of the holder, to carry out an action in the public interest, or to comply with an obligation legally acquired by the holder. </p>
            <br></br>

            <h3>REVOCATION OF AUTHORIZATION</h3>
            <p>Personal data subjects may revoke their consent to the processing of their personal data at any time, provided that this is not prevented by a legal or contractual provision. To this end, WNR shall establish simple and free mechanisms that allow the holder to revoke their consent, at least by the same means by which it was granted. It should be taken into account that there are two modalities in which the revocation of consent may occur: </p>
            <p>a.	The first can be for the totality of the consented purposes, i.e., WNR must stop processing the data of the owner completely;</p>
            <p>b.	The second can occur for specific types of processing, such as for advertising or market research purposes. With the second modality, that is, the partial revocation of consent, other purposes of the processing that the data controller, in accordance with the authorization granted, may carry out and with which the data subject agrees, remain unaffected.</p>
            <br></br>

            <h3>INFORMATION SECURITY AND SECURITY MEASURES</h3>
            <p>In development of the security principle established in the current regulations, WNR will adopt the technical, human and administrative measures that are necessary to provide security to the records avoiding their adulteration, loss, consultation, use or unauthorized or fraudulent access. </p>
            <br></br>

            <h3>WNR'S USE AND INTERNATIONAL TRANSFER OF PERSONAL DATA AND PERSONAL INFORMATION</h3>
            <p>Depending on the nature of the permanent or occasional relationships that any personal data subject may have with WNR, all of your information may be transferred abroad, subject to applicable legal requirements, with the acceptance of this policy, you expressly authorize the transfer of Personal Information. The information will be transferred for all relationships that may be established with WNR. Without prejudice to the obligation to observe and maintain the confidentiality of the information, WNR will take the necessary measures to ensure that such third parties are aware of and agree to comply with this Policy, with the understanding that the Personal Information they receive may only be used for matters directly related to the relationship with WNR, and only for the duration of the relationship, and may not be used or intended for any other purpose or purpose.</p>
            <p>WNR may also exchange Personal Information with governmental or other public authorities (including, without limitation, judicial or administrative authorities, tax authorities and criminal, civil, administrative, disciplinary and prosecutorial investigative bodies), and third parties involved in civil legal proceedings and their accountants, auditors, attorneys and other advisors and representatives, because it is necessary or appropriate: (a) to comply with applicable laws, including laws other than those of your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, and to respond to requests from public and government authorities other than those of your country of residence; (d) to enforce our terms and conditions; (e) to protect our operations; (f) to protect our rights, privacy, safety or property, yours or others; and (g) to obtain any applicable remedies or limit the damages that may be available to us.</p>
            <br></br>

            <h3>PROTECTION OF PERSONAL DATA WITHIN WNR</h3>
            <p>WNR, under the terms established in the regulations in force, will act as the PERSON RESPONSIBLE FOR THE PROCESSING OF PERSONAL DATA. The technology area is designated as the area that will receive, process and handle the requests of the owners, in the terms, terms and conditions established by the current regulations, for the exercise of the rights of access, consultation, rectification, updating, deletion and revocation referred to in the current regulations on personal data protection. In the event that you consider that WNR gave a use contrary to the authorized and applicable laws, you may contact us through a reasoned communication sent to the email ___________________.</p>
            <p>This manual is effective as of __, ___, 2022.</p>
            <br></br>




            </div>
        </DialogLayout>
    )
}
